import React from 'react';
//import data
import {footerData} from '../data'
import Socials from './Socials'

import {motion} from 'framer-motion';
import {fadeIn} from '../variants';

const staggerContainer = {
  hidden: {},
  show: {
    transition:{
      staggerChildren: 0.3,
      delayChildrem: 0.2,
    },
  }
};



const Footer = () => {
  const {about,links, program} = footerData
  return <section id='footer'>
    <footer className='section bg-[#808a6c] pt-[50px] pb-[50px]'>
    <div className='container mx-auto'>
      
      {/* grid */}
      <div className='text-white 
      flex flex-col lg:flex-row lg:justify-between 
      gap-x-6 gap-y-14' >

        <div className='flex-1'>
          {/**tytuł */}
          <div className='font-primary uppercase font-medium text-xl mb-6'>
            {program.title}
          </div>

          {/**Linki Polityka prywatności */}
          <ul className='flex flex-row justify-between items-center'>
            {program.items.map((item, index)=>{
              const {href, name} = item
              return <li key={index}> 
                
                <a href={href}>
                  {name}
                </a>
              
              </li>
            })}
            
          </ul>
        </div>
 
      </div>
    </div>
  </footer>
  <div className='bg-[#808a6c] flex justify-center'>
        <div className='text-white '>
          © WIKTORIA TRELA 2023. | WEB BY PIOTR WIEKIERA
        </div>
      </div>
  </section> 
};

export default Footer;
