import React from 'react';
import { Link } from "react-router-dom"
//import data
import {aboutData} from '../data'

//animacje
import {motion} from 'framer-motion';
import {fadeIn} from '../variants';
import { IoMdArrowForward } from 'react-icons/io';

const About = () => {
  const {title, subtitle1, subtitle2} = aboutData
  return <section id='about'
  className='bg-white lg:py-16 xl:pb-[100px]'>
    <div className="container mx-auto">
      <div className='flex flex-col lg:flex-row gap-x-[30px] items-center'>
        
        {/* liczby; jeśli liczba ma być wypełniona obrazem na koniec <div ... >01 dodać bg-clip-text text-transparent

        https://lh3.googleusercontent.com/fife/AKsag4PrAqWVY5-5WdtaiV2Gjozcrj7w8QZtjk-YwFBTbeF7FOLphH0WKncLS7obxO09cNwFriIN5f_KaqiHl2LhWy3th6fCMhGg704GKpG-F1TDtaeGBnIwUo78aUndwt4XDoOeALmGfdjXyXtlP_OcQN1tkFw_2RryyU7VcFg9Caiy_auZDCEB4EwPKnCEED902IdcTdUplCyqWXxj8d0j01SKP_MF2JTrE37mgsrWPvieTdUu2GuFjjHyIxrdjs5cQyiCp5DXGhvc1ZqIfCagE0L7xE8rxSXKvA1W7rmNByFIxDBg3CifziBtAUBv53swMGHo4Z-ICX5wiaJ9MsxF_lWW5cBE81kOJuSMo8FLRnLYiHrg7vC6CM8uO9wHRNKwUNMTxUoxw66eYfgUN9OLDhc_9806_1833Omd-UkWwl_xIlnvdcLvfzZLws8nzUI6LWyXnc1l88MbIR8iJhXz8qdpuQsvtH2xTtxtB0sQtwwy5WkzV7Sha2ps1CF8uzSYs5W9v0m7vmzjSMFZdzhcjF1sfv67FAJLkUqqY1xXnX8pJExdQZ_i7jyxOBXq6U6E5bj5pnSWnBE3mT2mxWxcYADz8dpc23Dy2kYqbnUsz5zfnXDLj24cu_37dZYVxV1imhoXKISRsRc2NBvEaHKy90BRE2Vn6WJRP3oSgtdlPenHNrChOdwie7Mm3qSZF9IQfnfzKI2HBgS3nH6OdL9JD2gTV91BMtngGEkQXnlz9LIyLiFaDw6BZxpZM1C03jPUlR2cHA_7B9Ar4PNxPOHzTFt7D7IQNTjG68iRJRLNrMWQX3_g7Ua9CPb14DPfK8NQtiEp1AzNdPWcDhoUXbiMNyBhS_tDfQpSTxqjU_5czgmGcMaIPSmCAQJ4TO-1cVPCmKTJXbWaMKAIaBZ17KhGhaTydAu8bW0_cBcOIPgxZ9D2zdYUWcz5G9n8818B4_o3eUgv1zzV9LDVIV7p62BpDpj2FnLi1RWI6403N16t45J1q9asC6eFgK9dLioMTyj8Kx_UqNbe1BHBldlobmFd7KzXMXM9ssYkENCKhDhfndWlOQt6k6dobrPsf23iZELoehrGropUNrHwI_d974ZpHuMKNwWfvAKfgKWfCCTiZ0BUOlESalKJt1_ShtncPzAGKRHytAmvkLMZL7NMuvN9p2P6AkacFwAetXaheL2nCFLmCfCf7DfaZXmXwpkcBszkQm1vHxtTM8IAjH-LTqYTheKPVsjWTmExTlJaMX0ptfTpurjw_SpnTkmGhqB4tPkpwXdd-2wYq5qTc8I4Z07sXGQrpQce8gVDVQhXT1KQCgFSS907eJRbALvwVYASWhxiOGiAcl6QX9h9IirWocEq9qHospr6sCgh5_S19izkzqnX9VkahS1hD7K0DtidIqs9mzry5XUeJpP6HqQoANYO0-ugGIzFvgb5Kcd_vtnaHAsweqPo2VJHiAU0ZiT2irEoZeWg0JucuRJnJ-HoOCR5YD5aHTjuGljDgSfMRNLbRdnbADPP6qwYHXbXKrn2223r-Xi0h5T_AAy9679v01Kj7cVVBak4wCj0POT-Baq3rSI=w1920-h927*/} 
        
        <motion.div 
        variants={fadeIn('right')} 
        initial = 'hidden'
        whileInView={'show'}
        viewport={{once: true, amount:0.1}}
        className='flex flex-col lg:flex-row flex-1'>
          
          <img
            src='https://lh3.googleusercontent.com/fife/AK0iWDyVnLjCQ3OiesKUgyJgULGmyRDR20ktxJlIN3kTbGsafW73LDS0qzH5-fQ_YbSrjeVq1MpbJJsQUHANyLewgMjJmlN5LvmH5NnCLAfXn7LVseWX7eFyCCv159Yfb9Ei4UZov4iG5VrcnMQrLqEWpD6USx6e8DgvdkdR7WPnIwdJzbteOKxluQbnWx_bXxAG24eNyhr0BvEnMq8Jl9y-J7LGczIUF333QFzl-EVKxnNntLfRGYzxYXxUCUeJWqg9EKyhk_kYurezY5xWrZzUW8Tq_2CdqyeVXDXaqrYB1OauBctzEONa2mlbVy_cqqmOKQ4JzRj_UzPQhrLjFD_QMWRFQp1vINuZOnxZBYYdleESq4MhjH0EP-5B1_jDqke4yxfOxfFyEuDGcScUoiJFyEMwhWXlNRv2PMUAbjxiDkuKQOGwnKFbUVyllE3b4z-IrCWKKkFocRxrFQc6nbDL10LztuIDniq3pf37ZBcGY668IJx0dh8xNh5_ccafsjGB70vl6_dZc2RIMZMbur6Ckkot_p7YQ5s1kkfnIrIPaTUZtHfZqQ8aaLdQPnawPPLqk_4LypRAEQ7y1MYW18fwlcIfzDtzV_okg13qb7JQG_xWEJe9R5-mzfsEK2L5uG7q3kUnPn7savDG6L85Mc2OkwRAjvsL2YB4yFrTl6fXOPDTBQBnT8456jwkmTkd4eJQDZe1vznNf0mlWMVnNRZ5_mgI8otoK82BBc5qlTzf1elQQf9cGfZihLayr0aGKS6QtwzWh9AbBpldk-UB1JNekT6LbtvGPfPYeQ_waoLLTEsakZ5B7c1R02j9keeUMA32ztWqdv0ChjEH1Onc_xSK3ql3l123qqtakKM5_f4gf3EzkMy2uy7VBcH959MIHIclKC9p86WViEBVnRRsyj0kB1qmgIT_53Wsl_a8TKKlYjSkW477f1peClZVObkiHgUpHA_VBfpCcmNh4b0mydoxe2WmvLoNsk4oMwagzrbebvS9GygtVJqYcTiT1P02h2YixwbfhZvJnH8NlkQf_U7fhyq-sRWUrLSdI1fUsvUzWpV2sAI_jZmEBXXun-Zeq4x4tn5AaCiZD1b6pTtNUwghWFRXNpvSHrkmQfSCOokt1aB2fqTkLo8FZyHxvI8GzRGQBpO0R8z0sEwlZ56q8Ew_1elMeUxYyLK0AYuWVDFKo1yeE1zO24JsHfu6Gz9mXDaVUnYXRN4iykpGw89zcHBFdPe73NcWBzJTDUy-MH9B03t2JffZPnOw5OkTXF1piuEzUT6yvwfCMDRS-4E_f6bU5LpQoyibgNMBdVRN-dlQlIeLmkvOV4YyhcWLrGDonN8D-Yj9YRSeRIqX4qVe31qSxMRTbQF_PZi01EkmWdIDg50zy4B6zWbp48-ScZioaZhgMOc-F-vW-ALSojnKyx2j3QC1nBnzUXGaP4C-KCia7CWDIM7d_EyI_btyPdBIzEGstnebyTxtjMYFWU-lRXQhE_PuvuxKp7PiQoDXFBCntrb6ZnEX_sbpqqnQfKovDFsw0dIRk8NI3rxOV9ZEvDW_iW7LVc4rpa9VET9pqyUxYu6_HTzgbiGydi0stw=w1920-h927'
            alt='Oto ja'
            width = '500'
            height= '400'
          />
        
        </motion.div>

      
        {/* text */}
        <motion.div 
          variants={fadeIn('left')} 
          initial = 'hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.1}} 
          className='flex-1 h-full xl:mt-48'>
          {/* tytuł */}
          <h3 className='h3'>{title}</h3>
          
          {/*akapity i przycisk*/}
          <div>
            <div className='text-left mb-[20px] pt-[25px]'>
              
              {/* text */}
              <p className=' mb-[20px]'>{subtitle1}</p>
              <p>{subtitle2}</p>
            </div>
            
          </div>

          <div className='container flex justify-center  mx-auto'>
            <button className='btn btn-sm lg:btn-lg
              btn-outline mx-auto lg:mx-0'>
            
              <Link to="/aboutMe">Więcej</Link>
              <IoMdArrowForward />
            </button>
          </div>
        </motion.div>

      </div>
    </div>

  </section>;
};

export default About;
