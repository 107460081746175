import { IoMdArrowForward } from 'react-icons/io';
import Logo from '../src/img/header/logo.svg';

import { GrFacebookOption } from 'react-icons/gr';
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoYoutube
} from 'react-icons/io';
import { IoLogoTiktok} from "react-icons/io5";

import { FiSend } from 'react-icons/fi';
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';


export const headerData = {
  logo: Logo,
};

export const navData = {
  items: [
    { href: 'home', name: 'Home' },
    { href: 'about', name: 'O mnie' },
    { href: 'obrazy', name: 'Obrazy' },
    { href: 'gallery', name: 'Tatuaże' },
    { href: 'wzory', name: 'Wolne projekty' },
    { href: 'contact', name: 'Kontakt' },
  ],
};

export const socialData = [
  { href: 'https://www.facebook.com/profile.php?id=100090464264573', icon: <GrFacebookOption /> },
  { href: 'https://www.instagram.com/trelatattoo/?fbclid=IwAR07Sdg8434g5LXrz8byNEqXrZ6HSpzMBYbRTkKGgC5RYvXEFbL8zekvD4I', icon: <IoLogoInstagram /> },
  { href: 'https://www.instagram.com/wiktoriatrelaart/', icon: <IoLogoInstagram /> },
  { href: 'https://www.pinterest.pt/trelatattoos/ ', icon: <IoLogoPinterest /> },
  //{ href: 'https://www.tiktok.com/@trelatattoo?lang=pl-PL', icon: <IoLogoTiktok /> },
  //{ href: 'https://www.youtube.com/channel/UCeUp4J8aHv7VhQGKNPmyN9Q', icon: <IoLogoYoutube /> },
];



export const heroData = {
    title: 'Wiktoria Trela',
    subtitle:
      'ART & TATTOO',
    btnText: 'Dowiedz się więcej',
    btnIcon: <IoMdArrowForward />,
  };
  
  export const aboutData = {
    title: 'Pochłania mnie malarstwo',
    subtitle1:
      'Pochłania mnie malarstwo olejne, które pozwala na ciągłe zmiany w trakcie tworzenia. Nie są to prace w bezpośredni sposób odnoszące nas do rzeczywistości, a bardziej do jej autorskiej reprezentacji. Zazwyczaj tworzę obrazy w dużych cyklach tematycznych, które są bardzo osobiste. Malarstwo oddziałuje na głębokim poziomie, odblokowuje intuicję i działa terapeutycznie. Czasem w transie malarskim używam tylko farb olejnych i dłoni. Sztuka wyraża trafniej, niż robią to słowa. Codzienne obcowanie z nią wzbogaca nasze wnętrza. Wierzę, że miejsce sztuki jest nie tylko w galeriach, ale przede wszystkim, w naszych domach. Sztuka powinna być żywa. Powinna nadawać charakter wnętrzu, koić oraz inspirować.',
    subtitle2:
      'Zapraszam Cię do mojego świata, gdzie można zatracić się w sztuce',
  };

  export const contactData = {
    title: 'BĄDŹMY W KONTAKCIE',
    info: [
      {
        title: 'Zapisy',
        subtitle:
        'Przy zapisach zamieść informacje tj.: opis pomysłu, przybliżony rozmiar, miejsce na ciele, preferowany termin oraz inspiracje lub jeden z moich wolnych projektów trelatattoo.zapisy@gmail.com',
        address: {
          icon: '',//<FaMapMarkerAlt />,
          name: 'OBRAZY Spodobały Ci się moje obrazy, chcesz dowiedzieć się więcej o ich dostępności lub zadać pytanie - napisz do mnie, a ja z największą przyjemnością opowiem Ci o nich więcej!'
          ,//'Racławicka 26/4, 30-075 Kraków',
        },
        phone: {
          icon: '',//<FaPhoneAlt />,
          number: 'PRESS & MEDIA Jeśli podobają Ci się moje prace i chciałbyś zaprosić mnie do wywiadu lub wernisażu lub innej formy współpracy chętnie ją nawiążę.',//'+49 93 30493943',
        },
        email: {
          icon: '',//<FaEnvelope />,
          address: 'TATUAŻE Jeśli podoba Ci się moja stylistka i chciałbyś posiadać tatuaż ode mnie lub chciałbyś umówić się na bezpłatne konsultacje zachęcam do skontaktowania się ze mną.',//'trelatattoo.zapisy@gmail.com',
        }
      },
    ],
    form: {
      name: 'Write your name here',
      email: 'Write your email address',
      message: 'Write your messages here',
      btnText: 'Send it',
    },
  };

  export const formData = {
    name: 'Imię i Nazwisko',
    email: 'Email',
    message: 'Wiadomość',
    attachment: 'Tu możesz również załączyć przykład',
    btnText: 'Wyślij',
  }
  
  export const footerData = {
    about: {
      title: 'No to jeszcze raz',
      subtitle:
        'Pamiętaj żeby się skontaktować',
      address: {
        icon: <FaMapMarkerAlt />,
        name: 'Racławicka 26/4, 30-075 Kraków',
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: '+49 34 36573355',
      },
      email: {
        icon: <FaEnvelope />,
        address: 'trelatattoo.zapisy@gmail.com',
      },
    },
    links: {
      title: '',
      items: [
        
      ],
    },
    program: {
      title: '',
      items: [
        { href: '/privacy-policy', name: 'POLITYKA PRYWATNOCI' },
        { href: '/terms-and-conditions', name: 'REGULAMIN' },
        { href: '/questions', name: 'PYTANIA I ODPOWIEDZI' },
      ],
    },
  };