import React, { useEffect } from 'react';
import {Link } from "react-router-dom";

import Footer from '../components/Footer';
import GallerySubSkills from '../components/GallerySubSkills';

const TattosPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Ustawia widok strony na górze po załadowaniu lub nawigacji
  }, []);

  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>
      
      {/* Galeria Wzory */}
      <GallerySubSkills />

      {/* stopka - najważniejsze info */}
      <Footer />
    </div>
  );
};

export default TattosPage;
