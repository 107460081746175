import React, { useState, useEffect, useMemo } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import {paternsData} from '../galeryFiles/dataPatterns'

const GallerySubWzory = () => {
  const {status,data} = paternsData
 
  //funkcja klikania na obraz
  const [isActive, setIsActive] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  
  const openModal = (index) => {
    setActiveImageIndex(index);
    setIsActive(true);
  }

  const closeModal = () => {
    setActiveImageIndex(null);
    setIsActive(false);
  }

  //w zależności od statusu albo galeria albo komunikat o błędzie
  if(status === "ok") {
    return (
      <section 
        section id='gallery' 
        className=' lg:py-16 xl:pb-[100px]'
      >
        <div className='container flex justify-center  mx-auto h2'> Wolne Wzory</div>
        <div className='container flex justify-center  mx-auto h7 pb-10'>
        Znajdziesz tu oryginalne projekty, które są gotowe do przeniesienia na skórę. Każdy wzór rysuję od zera i nie zgadzam na ich powielanie.
        </div>
        {/* box w którym jest galeria */}
        <div className='container mx-auto pb-10'>
          
          {/**galeria ogólne style */}
          <div 
            className='text-black flex flex-col lg:flex-row lg:justify-between gap-x-6 gap-y-14'
            style={{ display: "flex", flexWrap: "wrap"}}
          >
            {/* listowanie itemów z bazy */}
            {data.map((item, index) => (
              <div 
                key={index} 
                onClick={() => openModal(index)}
                className='cursor-pointer'
              >
                <img
                  src={item.url}
                  alt="Tu Powinien Być Obraz :("
                  width={item.width}
                  height={item.hight}
                />
              </div>
            ))}
          </div>

          
        </div>
        
        {/* Modal */}
        {isActive && (
          <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-75'>
            <div className='flex justify-center items-center  '>
              <div className='flex flex flex-col lg:flex-row lg:justify-between 
                gap-x-6 gap-y-14 relative top-[50px] fixed'>
                
                {/**obraz  */}
                <img className='h-[600px] lg:h-[800px] '
                  src={data[activeImageIndex].url} 
                  alt={data[activeImageIndex].url}
                />
                
                  {/**przycisk */}
                <button 
                  className=' absolute top-[-1000px] right-[-1100px] p-[1000px] text-white'
                  onClick={closeModal}
                >
                  <IoIosCloseCircleOutline fontSize="50px"/>

                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    );
    
  } else {
    return (
      <div>
        Wystąpił błąd, spróbój ponownie później.
      </div>
    )
  }

  
};


export default GallerySubWzory;
