import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import {Link } from "react-router-dom";

const AboutMeCVPage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0); // Ustawia widok strony na górze po załadowaniu lub nawigacji
  }, []);

  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>
      

    <section className='bg-white lg:py-16 xl:pb-[100px]'>
 
      {/*Start 
      https://lh3.googleusercontent.com/fife/AKsag4PrAqWVY5-5WdtaiV2Gjozcrj7w8QZtjk-YwFBTbeF7FOLphH0WKncLS7obxO09cNwFriIN5f_KaqiHl2LhWy3th6fCMhGg704GKpG-F1TDtaeGBnIwUo78aUndwt4XDoOeALmGfdjXyXtlP_OcQN1tkFw_2RryyU7VcFg9Caiy_auZDCEB4EwPKnCEED902IdcTdUplCyqWXxj8d0j01SKP_MF2JTrE37mgsrWPvieTdUu2GuFjjHyIxrdjs5cQyiCp5DXGhvc1ZqIfCagE0L7xE8rxSXKvA1W7rmNByFIxDBg3CifziBtAUBv53swMGHo4Z-ICX5wiaJ9MsxF_lWW5cBE81kOJuSMo8FLRnLYiHrg7vC6CM8uO9wHRNKwUNMTxUoxw66eYfgUN9OLDhc_9806_1833Omd-UkWwl_xIlnvdcLvfzZLws8nzUI6LWyXnc1l88MbIR8iJhXz8qdpuQsvtH2xTtxtB0sQtwwy5WkzV7Sha2ps1CF8uzSYs5W9v0m7vmzjSMFZdzhcjF1sfv67FAJLkUqqY1xXnX8pJExdQZ_i7jyxOBXq6U6E5bj5pnSWnBE3mT2mxWxcYADz8dpc23Dy2kYqbnUsz5zfnXDLj24cu_37dZYVxV1imhoXKISRsRc2NBvEaHKy90BRE2Vn6WJRP3oSgtdlPenHNrChOdwie7Mm3qSZF9IQfnfzKI2HBgS3nH6OdL9JD2gTV91BMtngGEkQXnlz9LIyLiFaDw6BZxpZM1C03jPUlR2cHA_7B9Ar4PNxPOHzTFt7D7IQNTjG68iRJRLNrMWQX3_g7Ua9CPb14DPfK8NQtiEp1AzNdPWcDhoUXbiMNyBhS_tDfQpSTxqjU_5czgmGcMaIPSmCAQJ4TO-1cVPCmKTJXbWaMKAIaBZ17KhGhaTydAu8bW0_cBcOIPgxZ9D2zdYUWcz5G9n8818B4_o3eUgv1zzV9LDVIV7p62BpDpj2FnLi1RWI6403N16t45J1q9asC6eFgK9dLioMTyj8Kx_UqNbe1BHBldlobmFd7KzXMXM9ssYkENCKhDhfndWlOQt6k6dobrPsf23iZELoehrGropUNrHwI_d974ZpHuMKNwWfvAKfgKWfCCTiZ0BUOlESalKJt1_ShtncPzAGKRHytAmvkLMZL7NMuvN9p2P6AkacFwAetXaheL2nCFLmCfCf7DfaZXmXwpkcBszkQm1vHxtTM8IAjH-LTqYTheKPVsjWTmExTlJaMX0ptfTpurjw_SpnTkmGhqB4tPkpwXdd-2wYq5qTc8I4Z07sXGQrpQce8gVDVQhXT1KQCgFSS907eJRbALvwVYASWhxiOGiAcl6QX9h9IirWocEq9qHospr6sCgh5_S19izkzqnX9VkahS1hD7K0DtidIqs9mzry5XUeJpP6HqQoANYO0-ugGIzFvgb5Kcd_vtnaHAsweqPo2VJHiAU0ZiT2irEoZeWg0JucuRJnJ-HoOCR5YD5aHTjuGljDgSfMRNLbRdnbADPP6qwYHXbXKrn2223r-Xi0h5T_AAy9679v01Kj7cVVBak4wCj0POT-Baq3rSI=w1920-h927*/}
      <div className="container mx-auto ">
        <div className='flex flex-col lg:flex-row gap-x-[30px] items-center'>

          <img
            src='https://lh3.googleusercontent.com/fife/AK0iWDwSLRv7g-bgMsHrtFuVmOvSltaRC_HgfqPKR4mwhtWOvKRoXdVApiX4lbQV06H7yAfZWFFrt9pL_UYAt9JbJiQhjYGyToGMecfTkCrjCCMJPYtJHXtlcoknIg2a8PmelC9YLE9idLStmwOUFv_AliiInZKZqEtb30c7ifyHBwTWCbjfai2VAwvttRGq725tLqvzBCi5FR7Vv4rhFcT6F7CiLmpaDGOIGfNCb_Pl1XaV9BJNB9nJ6bow247NIIgCOQWRRKakeNChPQwU8w7mYbOgz7_iOb9PwWg1yB8MvP0m6IqE6RoLU7RKJW367sJv3vCfZ2rjz7ZMus1IuTNsRvwm23SVAs9qXubEVfhXyB40t-j43DPtBblT_HZH4Hw0c2PuFqUMWfkyGlDaADsXC7slVejo1QrbokfvT9Dl5sSXHfgOnQ-bTVB0B9TWTdTTd_mdl2VxndVqYJsaMxA6XVRS0bJrU6DnYAGPN2i-eS7pRLDZBCxoEyVRuegjXAxVA5RW71aQh10h8bgN8jdYbHz38NVzuFUl7Y2GB_AP93vBeiurj2fykBQOeWAcVmV1ewHs-aNoGaoCbBEtSVa7VztDoYBlqJLOq47J3JWjzCJwpidbYDf8REMMoL0epZK6_r7ZvZyuNp06SSH3MRr4DU3B2-1xKt9y7xeMTlmgWmA91tjUMH2QCaumH74DgLOhy3zBkDSlSoCvJHiXO5KOY_My3XNnnQL7_O-reJvc0ejgyIcuZj7pXZ_VfTy3ggb2p7i5z2LX6FGdGhXlBxYxgqDy2gcStZiXtpqk2pfYAcMnDJBkLjlc5KrJGmbN60Dqc6br5Pp6zmatWyNrc4-BRJr1-mMjymekVgfxADg2zgLTnzN5NPQpvurRGOtTctD7kvs8lOvONzZDSi-rqW5UtgJO3MjXjrsPoPIb9pAOykC3bfabtOO4wvGztKHknd9AEAffiC_xmN3Q_ALz7KcXX1QEQ1ITQiBFn4daBqnS9jwlW2YuuiP8zJimSdAfrAPu9HcJ1qHJW2JaWl-SXj8mod58e-1NxrO23PDzH0j_9Cg3bjbEcJPwhURVbAsL_-q5vfSqfSRjcrnQLnRcIUpAW3u0ePrGAFNdIBYKafJmL9zlIJNfFsdT6ObfZJ6_VFeQFpk3_VEsvGptMuQvkzV58rm_3tB0oYjvSr0BISTfr6r2gSfKnhVtHGLW2H1Oe5c3bumPlJ7wXhe4M7oECPun86MbIHpGxVa6u8nwO8osq3NNFXvQwFmYM8yEwFuxMkLr8y6okzqKIbPRrMFDwlpzvhZ3_iV5UGnds9uVXJB5PMblMud3HK5_JWFDzE5Pywo_bzHpos3JdpppOWo8IIlJj6d2kjFwFNiG8t8aqJtgWRXjz2KFXo5UrUxfQqIAxNuw_hu504yG55g5h50cXKZVT-V7HqUh_Ts_a-pnSDYy6sGD5161cWJ1k6RAhtRlaJ7uZvFV9_1AbEW0H5gHyRfJRHNoGDwCbenOGvJttLn7mtDKrkXbALAGztcSGzNbV-NfN0Io9ad8uSWI6oriWje-wJCIhirXX_tSUe0r4ZuE-F0WlCzIRNGNHI-nBQ=w1920-h927'
            alt='Oto ja'
            width = '500'
            height= '400'
          />

          <div className='flex flex-col' >

            <h3 className='h3 '> 
              Pochłania mnie malarstwo
            </h3>


            <p className='text-left mb-[20px] pt-[25px] font-style: italic'>
            Pochłania mnie malarstwo olejne, które pozwala na ciągłe zmiany w trakcie tworzenia. Nie są to prace w bezpośredni sposób odnoszące nas do rzeczywistości, a bardziej do jej autorskiej reprezentacji. Zazwyczaj tworzę obrazy w dużych cyklach tematycznych, które są bardzo osobiste. Malarstwo oddziałuje na głębokim poziomie, odblokowuje intuicję i działa terapeutycznie. Czasem w transie malarskim używam tylko farb olejnych i dłoni. Sztuka wyraża trafniej, niż robią to słowa. Codzienne obcowanie z nią wzbogaca nasze wnętrza. Wierzę, że miejsce sztuki jest nie tylko w galeriach, ale przede wszystkim, w naszych domach. Sztuka powinna być żywa. Powinna nadawać charakter wnętrzu, koić oraz inspirować.
Zapraszam Cię do mojego świata, gdzie można zatracić się w sztuce
            </p>

          </div>
        
        </div>
      </div>
      {/*End */}
      
      {/*Start */}
      <div className="container mx-auto lg:py-16 xl:pb-[100px]">

        <div className='h6'>
          BIOGRAM
        </div>
        <div>
          Wiktoria Trela - ur. 2000 roku w Jędrzejowie, artystka, malarka studiująca w Krakowie. Zajmuje się tatuażami w stylistyce neotradycyjnej. Tworzy intymne malarskie cykle, które zabierają widza w świat artystki. W swoim dorobku ma kilkukrotnie zdobyte stypendium oraz nagrodę Rektora UP, indywidualne wystawy, jak i udział w kilkunastu wystawach zbiorowych. Swoje zainteresowania poza sztuką skupia wokół podróży, architektury oraz psychologii. 
        </div>
  
      </div>
      
      {/*Start */}
      <div className="container mx-auto lg:py-10 xl:pb-[50px]">

        <div className='h6'>
          WYSTAWY INDYWIDUALNE
        </div>

        <div className='flex flex-col gap-x-[30px] items-start' >
          
          <div>
            2023 - Olej i grafit | Samorządowe Centrum Kultury | Sędziszów
          </div>
          <div>
            2022 - The Lie They Told Her | Dwór Czeczów | Kraków
          </div>
        
        </div>
      </div>
      
      {/*Start */}
      <div className="container mx-auto lg:py-10 xl:pb-[50px]">
        <div className='h6'>
          WYSTAWY GRUPOWE
        </div>

      <div>

        <div className='text-[25px] xl:text-[25px] text-black font-primary leading-none mb-[20px] mt-[20px]'>
          2023
        </div>
        <div>
          Małe zwycięstwa | Muzeum Ogrodu Botanicznego UJ | Kraków
        </div>
        <div>
          Stado | Galeria Cztery Ściany | Kraków
        </div>
        <div>
          Stado | Galeria Szklana | Zator
        </div>

      </div>
      
      <div>
        <div className='text-[25px] xl:text-[25px] text-black font-primary leading-none mb-[20px] mt-[20px]'>
          2022
        </div>        
      
        <div>
          Mieszanina heterogeniczna | Cracow Art Week KRAKERS | ARTzona | Kraków
        </div>
        <div>
          Talenty 2022 | Wojewódzki Dom Kultury | Kielce
        </div>
        <div>
          Fragmenty | Galeria Zejście | Kraków
        </div>
        <div>
          Stado | Galeria 43/20 | Kraków
        </div>
        <div>
          Motywy pasyjne | Galeria na Strychu | Kraków
        </div>
      </div>

      <div>
        <div className='text-[25px] xl:text-[25px] text-black font-primary leading-none mb-[20px] mt-[20px]'>
          2021
        </div>   

        <div>
          PaintUP | Centrum Sztuki Współczesnej Wiewiórka | Kraków
        </div>
        <div>
          Feel St.Petersburg | Centrum Kultury Podgórza | Kraków
        </div>
        <div>
          Wystawa poplenerowa | Galeria Szklana | Zator
        </div>
        <div>
          Wystawa prac młodych artystów | Por Fiesta Club | Kraków
        </div>
        <div>
          Relacje | Cracow Art Week KRAKERS | Klub Kazimierz | Kraków
        </div>
        <div>
          Wystawa Szkiców Miesiąca | Galeria 43/20 | Kraków
        </div>
        <div>
          Światło | Galerie Zet | Ołomuniec (Czechy)
        </div>

             
      </div>
      
      </div>



    </section>
    
      {/* stopka - najważniejsze info */}
      <Footer />
      
    </div>
  );
};

export default AboutMeCVPage;
