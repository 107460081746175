{/**for use in QAPage */}

export const obrazy={
    data: 
    [
        {
            header:'1. Werniks',
            text:'W końcowej fazie obraz został pokryty wysokiej jakości werniksem błyszczącym na bazie żywicy ketonowej zabezpieczającym powierzchnię obrazu oraz podkreślającym głębie koloru. Tak zabezpieczony obraz można bez obaw oczyścić z kurzu oraz innych zabrudzeń. Werniks stosowany regularnie znacząco przedłuża żywotność obrazu. Werniks ten nie powoduje zmiany barw obrazu na przestrzeni lat oraz może być usunięty benzyną lakową.            '
        },
        {
            header:'2. Media',
            text:'Obraz namalowany został wysokiej jakości farbami olejnymi firmy Winsor & Newton i Renesans zgodnie ze sztuką malarską. Innymi mediami używanymi do wykonania pracy była terpentyna, olej lniany i medium alkydowe liquin.            '
        },
        {
            header:'3. Certyfikat',
            text:'Do obrazu dołączony jest certyfikat autentyczności sygnowany przez artystkę. Znajduje się na nim nr identyfikacyjny obrazu oraz podstawowe informacje o obrazie, które mogą być przydatne przy dalszej jego odsprzedaży oraz renowacji            '
        },
        {
            header:'4. Montaż',
            text:'Obraz możesz powiesić bezpośrednio po odbiorze przesyłki. Do tego celu potrzebne będą dwa wkręty/gwoździe odpowiednie do ściany w Twoim wnętrzu. Mocuję się je możliwie jak najszerzej na ścianie, na tyle głęboko by można było na nich zawiesić drewniane krosno obrazu. Zawieszenie obrazu w ten sposób gwarantuje, że obraz będzie idealnie przylegał do ściany.            '
        },
        {
            header:'5. Rama',
            text:'Boki obrazu są pomalowane dlatego dzieło świetnie prezentuję się również bez ramy. Jeśli jednak estetyka pomieszczenia wymaga oprawienia obrazu polecam udać się z obrazem do fachowca, który pomoże dopasować idealną ramę. Rama wówczas dobierana jest indywidualnie by wkomponować ją do Twojego wnętrza.            '
        },
        {
            header:'6. Barwy',
            text:'Kolory na zdjęciu mogą nieznacznie różnić się od otrzymanego oryginału. Obraz olejny zazwyczaj wygląda lepiej na żywo niż na zdjęciu gdyż dochodzi efekt 3D struktury farby, połysk i głębia.            '
        },
        {
            header:'7. Personalizacja obrazu',
            text:'Jeśli jest to np. obraz na prezent można go spersonalizować dodając ręcznie pisany cytat na odwrocie.             '
        },
        {
            header:'8. Bezpieczeństwo',
            text:'Obraz jest solidnie zapakowany w papier ochronny, folię stretch i karton. Krawędzie obrazu są dodatkowo wzmocnione. Obrazy o większych gabarytach są dodatkowo usztywniane by bezpiecznie przetrwały podróż.             '
        }
    ]
}

export const tattoFAQ={
    data: 
    [
        {
            header:'1. Jak umówić się na sesję?',
            text:[
                {
                    line:'Wiadomość powinna zawierać informacje na temat planowanego tatuażu, tzn. krótki opis tematu, motywy, miejsce na ciele i wielkość. Najlepiej jeśli załączysz zdjęcia poglądowe, mogą to być przykładowe tatuaże, grafiki, malarstwo, rysunki, szkice, zdjęcia motywów i wszystko co pomoże, zwizualizować Twoją wizję i w przyszłości stworzyć indywidualny projekt na tatuaż. Rezerwacja następuje po: wstępnych ustaleniach co do motywu tatuażu, jego wielkości i miejsca na ciele, zapisaniu konkretnej daty i godziny oraz wpłaceniu zadatku, osobiście lub na konto.'
                },
                {
                    line:'Dla potwierdzenia terminu wymagamy wpłaty zadatku w ciągu tygodnia (kwota ustalona z tatuatorem, zwykle 100-200 zł), na konto:'
                },
                {
                    line:'mBank'
                },
                {
                    line:'Samsara Izabela Solarz'
                },
                {
                    line:'90 1140 2004 0000 3902 7654 0258'
                },
                {
                    line:'W tytule przelewu należy wpisać „swoje imię i nazwisko, datę terminu, imię tatuatora” Umówienie terminu i wpłata zadatku oznacza akceptację naszych zasad. '
                }
            ]
        },
        {
            header:'2. Jak przygotować się na sesję?',
            text:[
                {
                    line:'Przede wszystkim bardzo ważne jest, aby wiedzieć, którą część ciała chcesz wytatuować żeby odpowiednio się ubrać. Ubranie powinno dawać dobry dostęp do tatuowanego miejsca i być komfortowe. Nie zakładaj na sesję ulubionego jasnego t-shirtu czy spodni, bo na pewno nie chcesz, aby pozostały na nich plamy z tuszu. Bardziej praktyczne będą świeżo wyprane czarne rzeczy, których straty nie będziesz żałować. Studio jest kameralne i nie mamy recepcji, która by się zajęła osobami towarzyszącymi, więc w momencie rozpoczęcia tatuowania osoby towarzyszące mogą pójść na kawę lub do parku obok. Radzę również moim klientom, którzy planują dłuższe sesje, aby dzień wcześniej nie pili alkoholu, aby dobrze odpoczęli, najedli się przed tatuażem, przygotowali jakiś rozpraszacz w postaci gry, słuchawek czy książki. Ważne jest, aby organizm był w dobrej kondycji.                     '
                }
            ]
        },
        {
            header:'3. Czy tatuowanie boli?',
            text:[
                {
                    line:'Oczywiście, tatuaże bolą! Ale wiemy już, że ból jest bardzo subiektywny. Intensywność bólu będzie zależała głównie od wielkości wzoru oraz wybranych obszarów. Igła do tatuażu jest całkowcie inna w odczucie od igły do pobierania krwi. Tatuaż bardziej przypomina mocne drapanie, ból podobny do pieczenia. Jednymi z bardziej bolesnych obszarów są wewnętrzne części ciała, zgięcia oraz bardzo kościste miejsca. Najtrudniejsze do wytrzymania są miejsca tj. krtań oraz żebra. W przypadku dziewczyn unikamy tatuowania podczas okresu, ponieważ mamy wrażliwe dni i tatuaż będzie bardziej odczuwalny.'
                }
            ]
        },
        {
            header:'4. Czy dostanę znieczulenie?',
            text:[
                {
                    line:'Polecam przyjść wypoczętym, najedzonym i zrelaksowanym, to najlepsze znieczulenie. Nie stosuję znieczulenia u moich klientów ze względu na utrudnione gojenie i ryzyko uczulenia.'
                }
            ]
        },
        {
            header:'5. Jakie tusze zostaną użyte? Czy materiały są jednorazowe?',
            text:[
                {
                    line:'Wszystkie tusze, których używam, są zatwierdzone przez rozporządzenie REACH, a wszystkie materiały są jednokrotnego użytku lub zabezpieczone w jednorazowe folie.'
                }
            ]
        },
        {
            header:'6. Ile kosztuje tatuaż?',
            text:[
                {
                    line:'Cena minimalna to 200zł. Każdy projekt wyceniany jest indywidualnie i ostateczna cena zależy od czasu wykonania, a ten od wielkości, skomplikowania, stylu, kolorystyki, a nawet miejsca na ciele, jak również rodzaju skóry. Czasem wykonanie trwa godzinę, a w przypadku większych kompozycji wiele kilkugodzinnych sesji. Zrobienie kilku małych tatuaży jednego dnia wychodzi taniej, niż rozłożenie ich na kilka sesji. W sesję wliczony jest czas na przygotowanie projektu (choć zawsze mam coś przygotowane wcześniej), dopasowanie wielkości,  odbicie kalki oraz przygotowanie stanowiska i klienta do zabiegu.'
                }
            ]
        },
        {
            header:'7. Ile trwa sesja całodzienna?',
            text:[
                {
                    line:'Maksymalnie 7 godzin, tatuowanie jednorazowo dłużej jest ciężkie dla obu stron. '
                }
            ]
        },
        {
            header:'8. Jak działa system zadatków i przesunięć sesji?',
            text:[
                {
                    line:'Generalnie po pozostawieniu zadatku zaczynam z Tobą współpracę, przygotowuję Twój rysunek i w związku z tym zadatek nie jest zwracany.  Zadatek jest dla nas gwarancja dotrzymania umówionego terminu. Jednak termin możesz przełożyć bez utraty pieniędzy z wyprzedzeniem przynajmniej 3 dni roboczych. W przypadku tatuaży wielosesyjnych, zadatek jest rozliczany na ostatniej sesji. W razie niedotrzymania terminu zadatek przepada na pokrycie strat poniesionych przez studio, wynikających ze zmiany harmonogramu pracy.'
                }
            ]
        },
        {
            header:'9. Czy można kupić voucher/bon podarunkowy?',
            text:[
                {
                    line:'Bony podarunkowe można kupić w studio lub napisać wiadomość poprzez formularz na stronie, mail lub instagramie ( wtedy wpłata na konto). Bon jest ważny przez rok od daty zakupu i może być na dowolna kwotę. Osoba otrzymująca bon już sama wybiera tatuatora ze studia i ustala z nim dogodny termin.'
                }
            ]
        },
        {
            header:'10. Czy muszę mieć skończone 18 lat?',
            text:[
                {
                    line:'Możliwe jest wykonanie tatuażu u osób niepełnoletnich między 16 - 18 rokiem życia, tylko w przypadku gdy rodzice lub prawni opiekunowie, przyjdą osobiście na sesję i wyrażą pisemną zgodę wypełniając formularze przed zabiegiem.'
                }
            ]
        },
        {
            header:'11. Mam tatuaż, który mi się nie podoba, czy mogę go naprawić?',
            text:[
                {
                    line:'Tak, ale jest to bardziej skomplikowane niż tatuowanie na czystej skórze. Dużo łatwiej zrobić tatuaż od podstaw niż poprawić nieudany. W takich przypadkach zawsze lubię osobiście porozmawiać z klientami, aby zobaczyć, w jakim stanie jest skóra i co tak naprawdę można zrobić. W wielu przypadkach stare tatuaże pozostawiają blizny lub ślady. Możemy tatuaż odświeżyć lub zakryć. Jeśli decydujemy się na cover musimy pamiętać, żeby był większy i ciemniejszy od pierwotnego wzoru. Bardzo ważnym jest, aby nowe pomysły odpowiadały możliwościom zakrycia starego tatuażu. Nie każdy projekt nadaje się do zakrycia. W niektórych przypadkach konieczny jest laser.'
                }
            ]
        },
        {
            header:'12. Czy po zrobieniu tatuażu mogę oddać krew?',
            text:[
                {
                    line:'RCKiK  zaleca odczekać 6 miesięcy od daty wykonania tatuażu, jednak po tym czasie śmiało można oddawać krew. '
                }

            ]
        },
        {
            header:'13. Jak dbać o tatuaż?',
            text:[
                {
                    line:'Właściwy proces gojenia ma wpływ na to jak tatuaż będzie wyglądał w przyszłości, dlatego bardzo ważne jest zastosowanie się do poniższych zaleceń.'
                },
                {
                    line:'* Przez 72 godziny po tatuowaniu wstrzymaj się od alkoholu, czy innych substancji oraz środków, które podwyższają ciśnienie, mogą one powodować obfite krwawienie, a w skrajnych przypadkach rozlanie barwnika pod skórą lub jego odrzucenie.'
                },
                {
                    line:'* Zaraz po zabiegu zostanie założony specjalny opatrunek, który należy nosić do dnia następnego. Można zostawić go trochę dłużej, ale maksymalnie do 24 godzin.'
                },
                {
                    line:'* Gdy zdejmiesz opatrunek, musisz niezwłocznie porządnie umyć tatuaż, ciepłą wodą z delikatnym mydłem, płynem do hig intymnej lub specjalnym płynem np. Easy Tattoo. Na tatuażu pod opatrunkiem powstanie galaretowata powłoczka, którą należy dokładnie zmyć. Myjemy czystą ręką, nie wolno używać gąbek.'
                },
                {
                    line:'* Po spłukaniu mydła ciepłą wodą należy wziąć czysty papierowy ręcznik, delikatnie zwilżyć go wodą i zdecydowanie ściągnąć pozostałości osocza z tuszem, aż tatuaż będzie matowy. Ręczniczek będzie jeszcze brudził kolorem tuszu.'
                },
                {
                    line:'* Po dokładnym umyciu i osuszeniu pozostaw tatuaż odsłonięty na 1 lub 2 godziny, żeby dokładnie wysechł i pooddychał. Nie nakładaj już żadnych opatrunków ( folii spożywczej, bandaży itp).'
                },
                {
                    line:'* Następnie nałóż cieniutką warstwę kremu tak, żeby szybko się wchłonął i nie zostało białych śladów. Specjalny krem można kupić u nas, polecamy Easy Tattoo, lub ewentualnie w aptece Bepanthen Plus krem.'
                },
                {
                    line:'* Nie dopuszczaj do zabrudzenia tatuażu, nie dotykaj go brudnymi rękami i nie pozwalaj innym go dotykać.'
                },
                {
                    line:'* Smarujemy 3-4 razy dziennie. Przed każdym smarowaniem należy umyć tatuaż jak wyżej, ale wystarczy odczekać kilka minut przed nałożeniem kremu.'
                },
                {
                    line:'*  Po około 3-4 dniach skóra zacznie się złuszczać. Jeśli wszystko przebiegło poprawnie powinny to być tylko cienkie skórki, a nie strupy. Nie wolno zdrapywać skórek, a tym bardziej strupków jeśli by powstały. Pamiętaj, że pojawienie się grubszych strupków spowodowane jest nieodpowiednim dbaniem o tatuaż i nie jest normalnym zjawiskiem. Muszą one odpaść same, najwięcej podczas mycia, w przeciwnym wypadku może dojść do utraty barwnika i powstania blizny.'
                },
                {
                    line:'*  Tatuaż smarujemy około 2-3 tygodnie, po tym czasie powinien być całkowicie wygojony. W trakcie gojenia nie wolno moczyć tatuażu długo w wodzie, ( tylko mycie miejscowe i prysznic ), mycie w wannie, basen i sauna w tym okresie odpada.'
                },
                {
                    line:'*  Również nie wskazane jest wystawienie tatuażu na słońce. Jeśli w trakcie gojenia będziesz mieć jakiekolwiek obawy lub wątpliwości, zawsze możesz do nas napisać. Niestosowanie się do powyższych instrukcji może spowodować wyblaknięcie, uszkodzenie i ubytki w tatuażu oraz różnego rodzaju zakażenia zagrażające zdrowiu. Musisz mieć również świadomość zmiany wyglądu tatuażu wraz z upływem czasu, w tym wpływu różnych czynników typu: rodzaj skóry, ekspozycja na światło słoneczne, pielęgnacja tatuażu w trakcie gojenia i później.Jeśli chcesz, żeby twój tatuaż wyglądał ładnie przez długie lata pamiętaj, żeby zadbać o skórę, dobrze jest używać balsamów czy innych preparatów nawilżających skórę, a w trakcie dłuższej ekspozycji na słońcu używaj filtrów, najlepiej 50.'
                }
            ]
        },
        {
            header:'14. Jak długo goi się tatuaż?',
            text:[
                {
                    line:'Będzie to zależeć od wielkości tatuażu, ilości wypełnienia, obszaru i innych składowych. W pierwszym tygodniu powinien się złuszczyć, a przez pierwsze 3 tygodnie od zrobienia należy na niego szczególnie uważać. '
                }
            ]
        },
        {
            header:'15. Czy mogę uprawiać sport po sesji?',
            text:[
                {
                    line:'Najlepiej poczekać przynajmniej tydzień, tatuaż nie powinien się pocić przez pierwsze kilka dni.'
                }
            ]
        },
        {
            header:'16. Ile czasu upłynie, zanim będę mógł się opalać?',
            text:[
                {
                    line:'Do czasu całkowitego wygojenia nie zaleca się wystawiania tatuażu na słońce. Można go zasłaniać przewiewnymi tkaninami. Po zagojeniu najlepiej zawsze chronić go kremem z filtrem przeciwsłonecznym. Słońce działa na tatuaż, jak powolny laser. Im bardziej dbasz o swoje tatuaże, tym dłużej będą wyglądać ładnie.'
                }
            ]
        },
        {
            header:'17. Czy konieczne będą poprawki?',
            text:[
                {
                    line:'Bardzo rzadko się to zdarza, jednak poprawki są konieczne to biorę je na swoje barki. Są w 100% darmowe, wyjątkiem są  obszary tj. jak dłonie, palce, kolana, łokcie, zgięcia,  w pobliżu dolnej części stopy i inne mocno ścieralne miejsca w których tusz bardziej prawdopodobnie się wybije, a ubytki będą ponownie wytatuowane. Na zgłoszenie chęci retuszu masz miesiąc od wykonaniu tatuażu.'
                }
            ]
        },
        {
            header:'18. Czy można tatuować na bliznach?',
            text:[
                {
                    line:'Można tatuować na bliznach, ale tylko na takich, które mają przynajmniej półtora roku. Musisz wiedzieć , że tatuaż na bliźnie zachowa się inaczej niż na zdrowej skórze i nie bardzo da się to przewidzieć. Może to być np. większy ubytek tuszu lub jego rozlanie. Nie każdy rodzaj czy styl tatuażu nadaje się na zakrywanie większych blizn, dlatego musisz zaufać naszemu doświadczeniu i przyjąć nasze sugestie i porady.'
                }
            ]
        },
        {
            header:'19. Jakie są przeciwwskazania do zabiegu?',
            text:[
                {
                    line:'Przychodząc na tatuaż nie możesz być pod wpływem alkoholu ani innych środków odurzających.'
                },
                {
                    line:' Nie wskazane jest tatuowanie podczas choroby, (grypa, przeziębienie itp), więc jeśli masz gorączkę to lepiej przełożyć sesję.'
                },
                {
                    line:' Również stan krótko po zabiegach, operacjach czy chorobie może nie wpłynąć dobrze na znoszenie zabiegu i późniejsze gojenie tatuażu.'
                },
                {
                    line:' Oczywiście ciąża jest przeciwwskazaniem.'
                },
                {
                    line:' Musisz mieć również świadomość, iż wykonanie zabiegu tatuażu może mieć wpływ na przebieg ewentualnych posiadanych chorób, takich jak choroby układu nerwowego (padaczka, stan poudarowy, przypadki utraty przytomności), alergie (pyłki roślinne, barwniki, metale itp.), choroby skóry (łuszczyca, atopowe zapalenie skóry, egzema), choroby serca, cukrzyca, astma, epilepsja, choroby związane z wysokim ciśnieniem krwi, hemofilia. Przed zabiegiem dostaniesz do podpisania oświadczenie, w którym deklarujesz między innymi świadomość powyższych punktów .'
                }
            ]
        },
        
    ]
}