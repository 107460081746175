import React, { useState, useEffect, useMemo } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import {tattosData} from '../galeryFiles/tattosData'

const GallerySubSkills = () => {
  const {status,data} = tattosData
 
  //funkcja klikania na obraz
  const [isActive, setIsActive] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  
  const openModal = (index) => {
    setActiveImageIndex(index);
    setIsActive(true);
  }

  const closeModal = () => {
    setActiveImageIndex(null);
    setIsActive(false);
  }

  //w zależności od statusu albo galeria albo komunikat o błędzie
  if(status === "ok") {
    return (
      <section 
        section id='gallery' 
        className='bg-white lg:py-16 xl:pb-[100px]'
      >
        <div className='container flex justify-center  mx-auto h2'>Tatuaże</div>
        <div className='container flex justify-left  mx-auto p-2'>Tatuuję od połowy 2022 roku, jeszcze daleka droga przede mną, ale ambicje mam ogromne i staram się im sprostać.</div>
        <div className='container flex justify-left  mx-auto p-2'>Znajdziesz mnie w Pracowni Tatuażu Artystycznego Samsara w Krakowie, która przechodzi regularne kontrole sanepidu. Jest to bardzo kameralne, kobiece studio, co gwarantuje komfort.</div>
        <div className='container flex justify-left  mx-auto p-2'>Styl neotradycyjny z kolorami bliskimi skóry, konturem i kompozycją współgrającą z ciałem jest mi najbliższy.</div>
        <div className='container flex justify-left  mx-auto p-2'>Motywy, które uwielbiam to roślinność, zwierzęta, oldschool w lżejszej wersji oraz postacie (szczególnie kobiece).</div>
        <div className='container flex justify-left  mx-auto p-2'>Imponują mi prace artystów tj: Chris Green, Jentonic, Yu Jinpil, Kubrick, Lorena Morato, Santi Bord, Nelli Nouveau, Hannah Flowers, Jean Paul Maratt, Strychnine, Marta Adan, Kat Abdy, Maiza, Charly Tattoo, Filouino, Luna Lucero, Macy, Sneaky Mitch, Maria Dolg, Evin Pfaff, Robin Kemper oraz wielu innych.</div>
        <div className='container flex justify-left  mx-auto p-2'>Dlaczego tatuuję?
 Od zawsze spędzałam długie godziny rysując, a o tatuażach marzyłam już za dzieciaka. Studiowałam architekturę, jednak obroniłam na 5 sztukę i edukację, mam wiele wystaw za sobą. Kolekcjonowałam magazyny i albumy tatuatorskie, kilka razy do roku jeździłam na konwenty. Przełomem był Tatoofest w 2017 roku, gdzie pierwszy raz poczułam się naga w tłumie mocno wytatuowanych ludzi. Wiedziałam, że sama muszę zebrać dużą kolekcję prac na swoim ciele. Gdy dostałam się na praktyki od razu kupiłam maszynkę z wysokiej półki i zestaw kolorowych tuszy, tak żeby nie odpuścić. Trzeba wiele cierpliwości, samokrytyki i poświęcenia, szczególnie na początku. Daję z siebie 100% i mam nadzieję, że nigdy nie przestanę się rozwijać
</div>
        {/* box w którym jest galeria */}
        <div className='container mx-auto pb-10'>
          
          {/**galeria ogólne style */}
          <div 
            className='text-black flex flex-col lg:flex-row lg:justify-between gap-x-6 gap-y-14'
            style={{ display: "flex", flexWrap: "wrap"}}
          >
            {/* listowanie itemów z bazy */}
            {data.map((item, index) => (
              <div 
                key={index} 
                onClick={() => openModal(index)}
                className='cursor-pointer'
              >
                <img
                  src={item.url}
                  alt={item.url}
                  width={item.width}
                  height={item.hight}
                />
              </div>
            ))}
          </div>

          
        </div>
        
        {/* Modal */}
        {isActive && (
          <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-75'>
            <div className='flex justify-center items-center  '>
              <div className='flex flex flex-col lg:flex-row lg:justify-between 
                gap-x-6 gap-y-14 relative top-[50px] fixed'>
                
                {/**obraz  */}
                <img className='h-[600px] lg:h-[800px] '
                  src={data[activeImageIndex].url} 
                  alt={data[activeImageIndex].url}
                  
                  
                />

                
                  {/**przycisk */}
                <button 
                  className=' absolute top-[-1000px] right-[-1100px] p-[1000px] text-white'
                  onClick={closeModal}
                >
                  <IoIosCloseCircleOutline fontSize="50px"/>

                </button>
                {/*
                <div className='pt-[10px] lg:pt-[100px] p-2 w-[400px]  bg-black bg-opacity-10
                flex-coll gap-y-1.5 inset-y-0 right-0'>
                 */}
                  {/**tekst  
                  <div  className='text-white '>
                    <h4 className='h4'> {data[activeImageIndex].title}</h4>
                  </div>

                  <div  className='text-white py-5'>
                    <h5 className='h5'>{data[activeImageIndex].comment}</h5>
                  </div>
                </div>
                  */}
              </div>
            </div>
          </div>
        )}
      </section>
    );
    
  } else {
    return (
      <div>
        Wystąpił błąd, spróbój ponownie później.
      </div>
    )
  }

  
};


export default GallerySubSkills;
