import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import MainPage from './pages/MainPage';
import ContactPage from './pages/ContactPage';
import GalleryPage from './pages/GalleryPage';
import TattosPage from './pages/TattosPage';
import AboutMeCVPage from './pages/AboutMeCVPage'
import PaingsPage from './pages/PaintingsPage'
import QAPage from './pages/QAPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/aboutMe" element={<AboutMeCVPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/tattos" element={<TattosPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/paintings" element={<PaingsPage />} />
        <Route path="/questions" element={< QAPage/>} />
        <Route path="/privacy-policy" element={< PrivacyPolicyPage/>} />
        <Route path="/terms-and-conditions" element={< TermsPage/>} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
