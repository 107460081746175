import React, {useState} from 'react';
//impoirt data
import {navData} from '../data'
//import components
import Socials from './Socials'

import {Link} from 'react-scroll'


const NavMobile = () => {
  const {items} = navData

  return <nav className='w-full h-full flex flex-col 
  justify-evenly overflow-hidden'>
    <ul className='flex flex-col 
    justify-center items-center gap-y-6 py-6 mb-8'>
      {items.map((item, index) => {
        return<li key={index}>  
          <Link 
            to={item.href}
            activeClass='active'
            spy={true}
            smooth={true}
            duration={500}
            offset={-70}
            className='text-2xl font-primary uppercase'
            > 
            {item.name}
          </Link>
        </li>
      })}
    </ul> 

    <div className='text-2xl'>
      <Socials />
    </div>

  </nav>;


};

export default NavMobile;
