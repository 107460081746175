import React, { useEffect } from 'react';
import {Link } from "react-router-dom";

import Footer from '../components/Footer';
import GallerySubSkills from '../components/GallerySubSkills';

const PrivacyPolicyPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Ustawia widok strony na górze po załadowaniu lub nawigacji
  }, []);

  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>
      
      <section className='bg-white lg:py-16 xl:pb-[100px]'>
        {/* Header */}
        <div className="container mx-auto pb-[60px]">
            <div className='flex flex-col lg:flex-col gap-x-[30px] items-center'>
                <div className='h3'>
                POLITYKA PRYWATNOSCI
                </div>
                <div className='h6'>
                https://wiktoriatrela.pl/
                </div>
            </div>
        </div>
        
        <div className="container mx-auto flex flex-col gap-y-4">
            <div className="h7">I. Administrator danych osobowych</div>
                <div className='ml-5'>Informuję, że Administratorem danych osobowych użytkowników strony internetowej https://wiktoriatrela.pl/ jest Wiktoria Trela .</div>
            <div className="h7">II. Dane kontaktowe Administratora danych osobowych</div>
                <div className='ml-5'>Adres email: trelatattoo.zapisy@gmail.com</div>
            <div className="h7">III. Cele i podstawy przetwarzania danych osobowych</div>
                <div className='ml-5'>
                    <div>1) W celu udzielenia odpowiedzi na zapytania złożone za pomocą formularza kontaktowego droga mailową przetwarzane są następujące dane osobowe:</div>
                    
                    <div className='ml-5'>
                        <div>a) Imię i nazwisko</div>
                        <div>b) Adres email</div>
                    </div>
                
                    <div>Podstawą prawną przetwarzania wskazanych danych osobowych jest art. 6 ust. 1 lit. b RODO, który umożliwia przetwarzanie danych osobowych, które są konieczne do wykonania umowy lub podjęcia czynności zmierzających do jej zawarcia na żądanie osoby, której dane dotyczą. W przypadku, gdy zapytanie nie dotyczy bezpośrednio oferty i niestanowi działania w celu zawarcia umowy, pozostawienie danych w formularzu kontaktowym stanowi jednoznaczne okazanie woli osoby, która pozostawiła dane, czyli spełnia warunki wyrażenia zgody wskazane w art. 4 pkt. 11 i art. 7 RODO, a podstawą przetwarzania takich danych osobowych jest art. 6 ust. 1 lit. a RODO, zezwalający na przetwarzanie danych osobowych na podstawie dobrowolnie udzielonej zgody, będącej każdym jednoznacznym, konkretnym i dobrowolnym okazaniem woli osoby, której dane dotyczą.</div>
                    
                    <div>2) W celu analitycznym, tj. badania i analizowania aktywności na stronie internetowej wiktoriatrela.pl, przetwarzane są następujące dane osobowe:</div>
                    <div className='ml-5'>
                        <div>a) data i godzina odwiedzin strony,</div>
                        <div>b) rodzaj systemu operacyjnego,</div>
                        <div>c) przybliżona lokalizacja,</div>
                        <div>d) rodzaj przeglądarki internetowej wykorzystywanej do przeglądania strony,</div>
                        <div>e) czas spędzony na stronie,</div>
                        <div>f) odwiedzone podstrony,</div>
                        <div>g) podstrona, gdzie wypełniono formularz kontaktowy.</div>
                    </div>
                    <div className='ml-5'>Podstawę przetwarzania wskazanych danych osobowych stanowi art. 6 ust. 1 lit. f RODO, który
                        zezwala na przetwarzanie danych osobowych, jeżeli w ten sposób Administrator Danych Osobowych realizuje swój prawnie uzasadniony interes – uzasadniony interes Administratora w tym wypadku stanowi monitorowanie aktywności użytkowników na stronie internetowej.</div>
                    <div>3) W celu wykorzystywania cookies na stronie internetowej, Administrator Danych Osobowych przetwarza informacje tekstowe (zasady stosowania cookies zostały opisane w odrębnym punkcie). Podstawą prawną przetwarzania w tym zakresie jest art. 6 ust. 1 lit. a RODO, umożliwiający przetwarzanie danych osobowych na podstawie dobrowolnie udzielonej zgody, która może zostać udzielona przy pierwszym wejściu na stronę internetową, kiedy pojawia się zapytanie o zgodę na wykorzystanie cookies;</div>
                    <div>4) W celu administrowania stroną internetową Administrator Danych Osobowych przetwarza następujące dane osobowe:</div>
                    <div className='ml-5'>
                        <div>a) adres IP,</div>
                        <div>b) data i czas serwera,</div>
                        <div>c) informacje o przeglądarce internetowej,</div>
                        <div>d) informacje o systemie operacyjnym</div>
                    </div>
                    <div className='ml-5'>dane te są zapisywane automatycznie w tzw. logach serwera, przy każdorazowym korzystaniu z serwisu. Administrowanie stroną internetową bez użycia serwera i zastosowania powyższego automatycznego zapisu nie byłoby możliwe. Administrator przetwarza dane osobowe we wskazanym wyżej celu na podstawie art. 6 ust. 1 lit. f RODO, który pozwala na przetwarzanie danych osobowych, w przypadku realizacji prawnie uzasadnionego interesu Administratora Danych Osobowych, którym w tym wypadku jest administrowanie stroną internetową i zapewnienie jej prawidłowego działania.</div>
                </div>

            <div className="h7">IV. Odbiorcy danych osobowych</div>
            <div className='ml-5'>
                <div>1. Dane osobowe przetwarzane przez Administratora danych osobowych, mogą zostać udostępnione organom publicznym wyłącznie na podstawie przepisów powszechnie obowiązującego prawa lub decyzji właściwego organu.</div>
                <div>2. Dane osobowe mogą być udostępnione podmiotom świadczącym na rzecz Administratora danych osobowych usługi hostingu oraz obsługi strony internetowej.</div>
            </div>

            <div className="h7">V. Prawa osób, których dane dotyczą</div>
            <div className='ml-5'>
                <div>1. Osoba, której dane osobowe są przetwarzane w ramach działania strony internetowej wiktoriatrela.pl, ma prawo do:</div>
                <div className='ml-5'>
                    <div>a) Dostępu do danych osobowych i otrzymania ich kopii,</div>
                    <div>b) Żądania sprostowania lub uzupełnienia danych osobowych w przypadku, gdy dane osobowe są odpowiednio – nieprawidłowe lub niekompletne,</div>
                    <div>c) Prawo do żądania usunięcia danych osobowych – osoba, której dane dotyczą, w przypadku, gdy uzna, że nie ma podstaw, by Administrator danych osobowych przetwarzał jej dane osobowe, może żądać ich usunięcia.</div>
                    <div>d) Ograniczenia przetwarzania jej danych osobowych – osoba, której dane dotyczą może żądać, aby Administrator danych osobowych ograniczył przetwarzanie jej danych osobowych wyłącznie do ich przechowywania lub wykonywania na nich innych czynności uzgodnionych z nią, jeżeli:</div>
                        <div className='ml-5'>
                            <div>− dane są nieprawidłowe lub przetwarzane bez podstawy prawnej,</div>
                            <div>− osoba, której dane dotyczą nie chce ich usunięcia z uwagi na konieczność zachowania danych do ustalenia, dochodzenia lub obrony roszczeń lub na czas rozpoznania sprzeciwu wobec przetwarzania danych osobowych.</div>
                        </div>
                    <div>e) Wniesienia sprzeciwu wobec przetwarzania danych osobowych – wniesienie sprzeciwu jest możliwe w dwóch przypadkach:</div>
                        <div className='ml-5'>
                            <div>− Przetwarzanie danych osobowych do celów marketingu bezpośredniego: Osoba, której dane dotyczą ma prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych w celu marketingu bezpośredniego. W razie wniesienia sprzeciwu na tej podstawie, Administrator danych osobowych ma obowiązek zaprzestać przetwarzania danych osobowych.</div>
                            <div>− Sprzeciw z uwagi na szczególną sytuację: Osoba, której dane dotyczą może złożyć sprzeciw wobec przetwarzania jej danych osobowych na podstawie prawnie uzasadnionego interesu w celach innych niż marketing bezpośredni. W sprzeciwie należy wskazać, jaka szczególna sytuacja dotyczy osoby, której dane dotyczą, która ma stanowić uzasadnienie dla żądania zaprzestania przetwarzania danych osobowych. Administrator danych osobowych zaprzestanie przetwarzania danych osobowych, chyba, że wykaże, że podstawy dalszego przetwarzania są nadrzędne wobec praw osoby, której dane dotyczą lub też, że dane te są konieczne do ustalenia, dochodzenia lub obrony roszczeń.</div>
                        </div>
                    <div>f) Przenoszenia danych – osoba, której dane dotyczą, może na swoje żądanie otrzymać w dane osobowe, które jej dotyczą, zapisane w formie elektronicznej, a które zostały przekazane Administratorowi na podstawie udzielonej zgody. Osoba, której dane dotyczą może zlecić Administratorowi przesłanie ich bezpośrednio innemu podmiotowi.</div>
                    <div>Prawa wskazane powyżej można realizować, kierując wiadomość email na adres:</div>
                    <div>trelatattoo.zapisy@gmail.com</div>
                </div>

            </div>

            <div className="h7">VI. Prawo do cofnięcia zgody na przetwarzanie danych osobowych</div>
                <div className='ml-5'>
                    <div>1. Osoba, której dane są przetwarzane na podstawie udzielonej zgody na przetwarzanie danych osobowych, może w każdym czasie wycofać zgodę. Wycofanie zgody na przetwarzanie danych osobowych nie ma wpływu na legalność przetwarzania danych osobowych sprzed momentu jej wycofania.</div>
                    <div>2. Uprawnienie do cofnięcia zgody na przetwarzanie danych osobowych może być zrealizowane poprzez skierowanie wiadomości email na adres: kontakt@samsaratattoo.pl.</div>
                </div>

            <div className="h7">VII. Prawo do złożenia skargi do organu nadzorczego</div>
                <div className='ml-5'>
                    <div>1. Osoba, która uważa, że dotyczące jej dane osobowe są przetwarzane w sposób nieprawidłowy i niezgodny z prawem, jest uprawniona do złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</div>
                </div>

            <div className="h7">VIII. Okresy przechowywania danych osobowych</div>
                <div className='ml-5'>
                    <div>1. Dane osobowe są przechowywane wyłącznie przez okres niezbędny do osiągnięcia założonego celu ich przetwarzania. Po upływie okresu przetwarzania dane osobowe są nieodwracalnie usuwane lub niszczone.</div>
                    <div>2. Administrator danych osobowych przetwarza dane przez następujące okresy:</div>
                    <div className='ml-5'>
                        <div>a) Czas trwania umowy i wygaśnięcia roszczeń z niej wynikających – w przypadku danych osobowych, które zostały zebrane i są przetwarzane w celu zawarcia i wykonania umowy, jak również w celu podjęcia czynności, które prowadzą do zawarcia umowy i są podejmowane na żądanie osoby, której dane dotyczą.</div>
                        <div>b) Do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania – w przypadku danych osobowych, dla których podstawą przetwarzania jest zgoda osoby, której dane dotyczą.</div>
                        <div>c) Do czasu utraty przydatności lub zdezaktualizowania się danych osobowych, przy czym dane nie są przechowywane dłużej niż 3 lata – w stosunku do danych osobowych przetwarzanych przez Administratora Danych Osobowych w celach analitycznych, administrowania stroną internetową oraz wykorzystania plików cookies.</div>
                        <div>d) Do czasu skutecznego wniesienia sprzeciwu wobec przetwarzania danych osobowych lub osiągnięcia celu przetwarzania, jednak nie dłużej niż 3 lata – w stosunku do pozostałych danych osobowych, dla których podstawę ich przetwarzania stanowi uzasadniony interes Administratora danych osobowych.</div>
                    </div>
                </div>
            
            <div className="h7">IX. Przekazywanie danych osobowych do państw trzecich</div>
                <div className='ml-5'>
                    <div>1. Prowadząc przetwarzanie danych osobowych, Administrator korzysta również z rozwiązań technologicznych dostarczanych przez podmioty posiadające siedzibę poza Unią Europejską – Google, Automattic Inc. (dostawca usługi WordPress). W konsekwencji, zgodnie z odpowiednimi postanowieniami RODO, te podmioty są traktowane jak podmioty z państwa trzeciego.</div>
                    <div>2. RODO przewiduje ograniczenia dotyczące możliwości przekazywania danych osobowych poza Unię Europejską, tj. do tzw. Państw trzecich. Co do zasady w państwach trzecich nie stosuje się przepisów europejskich, stąd ochrona danych osobowych może być niewystarczająca, co powoduje, że każdy administrator ma obowiązek ustalić podstawę prawną przekazania tam danych osobowych.</div>
                    <div>3. Administrator danych osobowych oświadcza i zapewnia, ze przy używaniu usług i techologii służących do przetwarzania danych osobowych dostarczanym przez podmioty z państw trzecich – Stanów Zjednoczonych, są one przekazywane wyłącznie takim podmiotom, które przystąpiły do programu Privacy Shield.</div>
                    <div>4. Program Privacy Shield został zatwierdzony jako gwarantujący należyty poziom ochrony danych osobowych na podstawie decyzji wykonawczej Komisji Europejskiej z dnia 12 lipca 2016 r. (szczegóły dostępne pod adresem https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_pl).</div>
                    <div>5. Podmiotu, które uczestniczą w programie Privacy Shield, gwarantują przestrzeganie wysokich standardów ochrony danych osobowych obowiązujących w Unii Europejskiej. Korzystanie z ich usług i dostarczanych rozwiązań technologicznych tym samym jest zgodne z prawem.</div>
                    <div>6. Jeżeli mają Państwo dalsze pytania bądź wątpliwości, w każdym czasie Administrator danych osobowych udzieli dodatkowych wyjaśnień.</div>
                    <div>7. W każdej chwili przysługuje Państwu prawo do uzyskania kopii danych osobowych, jakie zostały przekazane do państwa trzeciego.</div>
                </div>
            
            <div className="h7">X. Wymóg podania danych</div>
                <div className='ml-5'>
                    <div>1. Podanie danych osobowych jest dobrowolne, ale niezbędne do skorzystania z funkcjonalności strony internetowej samsaratattoo.pl, w tym w szczególności z formularza kontaktowego.</div>
                    <div>2. W przypadku korzystania z formularza kontaktowego, konieczne jest pozostawienie imienia, nazwiska oraz adresu email – bez tych danych Administrator nie jest w stanie skutecznie nawiązać kontaktu zwrotnego i udzielić odpowiedzi na pozostawioną wiadomość.</div>
                </div>
            
            <div className="h7">XI. Cookies</div>
                <div className='ml-5'>
                    <div>1. Strona samsaratattoo.pl wykorzystuje w swoim działaniu pliki cookies, które stanowią krótkie informacje tekstowe, zapisywane w przeglądarce internetowej.</div>
                    <div>2. Stosując pliki cookies, przy ponownym połączeniu ze stroną internetową, strona rozpoznaje urządzenie, na którym użytkownik ją otwiera. Pliki mogą być odczytywane przez system stosowany przez Administratora danych osobowych, jak również przez dostawców usług, które są wykorzystywane w ramach działania strony samsaratattoo.pl, dostarczanych głównie przez Google.</div>
                    <div>3. Część ze stosowanych plików cookies ma charakter zanonimizowany. To oznacza, że bez dodatkowych informacji nie jest możliwe zidentyfikowanie użytkownika.</div>
                    <div>4. Stosowana przez Panią/Pana przeglądarka dopuszcza domyślnie korzystanie z plików cookies na urządzeniach służących użytkownikowi do przeglądania stron internetowych. Jest to powód, dla którego przy pierwszej wizycie na stronie internetowej pojawia się komunikat z prośbą o zgodę na korzystanie z plików cookies.</div>
                    <div>5. Strona samsaratatto.pl korzysta z plików cookies ze względu na następujące funkcje (w razie, gdyby informacje okazały się niewystarczające, prosimy o kontakt):</div>
                        <div className='ml-5'>
                            <div>a) zapewnianie bezpieczeństwa — pliki cookies mają służyć m.in. uwierzytelnieniu użytkowników w celu zapewnienia ochrony danych osobowych użytkownika przed dostępem osób nieupoważnionych;</div>
                            <div>b) stan sesji — pliki cookies zawierają m.in. informacje o tym, jak odwiedzający korzystają ze strony internetowej, np. które podstrony są najczęściej wyświetlane i jak długo. Pozwalają również na identyfikację wyświetlanych błędów. Pliki cookies służące do zapisywania tzw. „stanu sesji” pomagają zatem ulepszać usługi i zwiększać komfort przeglądania stron;</div>
                            <div>c) tworzenie statystyk — pliki cookies są służą do tego, aby przeanalizować, w jaki sposób użytkownicy korzystają ze strony internetowej (jak wielu otwiera stronę internetową, jak długo na niej pozostają, które treści wzbudzają największe zainteresowanie etc.). Dzięki temu można stale ulepszać serwis i dostosowywać jego działanie do preferencji użytkowników. W celu śledzenia aktywności i tworzenia statystyk Admnistrator danych osobowych korzysta z narzędzi Google’a, takie jak Google Analytics.</div>
                        </div>
                    
                    <div>6. Jeżeli użytkownik nie chce, by pliki cookies były wykorzystywane przy korzystaniu ze strony internetowej, należy zmodyfikować ustawienia przeglądarki internetowej poprzez skorzystanie z opcji całkowitej blokady automatycznej obsługi plików cookies lub włączenia każdorazowych powiadomień o zapisaniu cookies na urządzeniu. Ustawienia można zmieniać w dowolnym momencie.</div>
                    <div>7. Wyłączenie lub ograniczenie zastosowania i obsługi plików cookies może spowodować znaczące trudności w korzystaniu ze strony internetowej ,w tym np. dłuższy okres jej ładowania lub ograniczenie w możliwości skorzystania z jej funkcjonalności.</div>
                </div>
            
            <div className="h7">XII. Logi serwera</div>
                <div className='ml-5'>
                    <div>1. Niektóre zdarzenia inicjowane przez użytkowników powodują zapisanie informacji w formie logowań na serwerze, na którym zapisano stronę internetową. Dane pochodzące z tzw. Logów serwera, są wykorzystywane wyłącznie w celu prawidłowego administrowania stroną internetową i zapewnienia odpowiedniej obsługi strony, w tym znajdującego się na niej formularza kontaktowego.</div>
                    <div>2. Zapisowi podlegają dane dotyczące adresu URL, informacje o adresie IP i przeglądarce użytkownika. W formie logów serwera mogą ponadto zostać zapisane informacje o działaniach użytkowników, w tym np. adres email podany w treści formularza kontaktowego dostępnego na stronie. Zapisane w tej formie logi są dostępne w narzędziach wykorzystanych do stworzenia i zarządzania stroną internetową oraz jej funkcjonalnościami.=</div>
                    <div>3. Dane, które są zapisywane jako logi serwera, o których mowa w ust. 2 zd. 1 powyżej, nie są kojarzone z konkretnymi osobami, korzystającymi ze strony internetowej i służą wyłącznie do administrowania nią.</div>
                </div>
            
            <div className="h7">XIII. Profilowanie i zautomatyzowane podejmowanie decyzji</div>
                <div className='ml-5'>
                    <div>1. Administrator danych osobowych informuje, że na podstawie danych osobowych, zbieranych za pośrednictwem strony internetowej samsaratattoo.pl mogą być podejmowane decyzje w sposób zautomatyzowany, w tym w oparciu o profilowanie.</div>
                    <div>2. Profilowanie oznacza dowolną formę zautomatyzowanego przetwarzania danych osobowych, które polega na wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących efektów pracy tej osoby fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub przemieszczania się.</div>
                    <div>3. Profilowanie prowadzone za pośrednictwem strony internetowej samsaratattoo.pl polega stałym monitorowaniu użytkowników z zastosowaniem narzędzi Google Analytics, w tym w szczególności danych związanych z wejściami na stronę czy lokalizacją.</div>
                    <div>4. Profilowanie jest stosowane, by pozyskać informacje o zasięgu strony internetowych i osobach, potencjalnie zainteresowanych usługami Administratora danych osobowych i jest niezbędne, by dostosować treści udostępniane na stronie do preferencji użytkowników. Jest ono dokonywane w oparciu o dane pochodzące z zastosowania własnych plików cookies Google Analytics oraz adresów IP urządzeń.</div>
                    <div>5. Działania wskazane w ust. 4 powyżej są niezbędne z uwagi na stosowanie przez Administratora danych osobowych działań remarketingowych opartych o funkcjonalności Google Adwords. Zautmatyzowane podejmowanie decyzji polega w tym wypadku na kierowaniu treści reklamowych do osób, które poprzednio odwiedziły serwis. Treści reklamowe są wyświetlane w modułach reklamowych dostępnych po przejściu na inne strony internetowe.</div>
                    <div>6. Zautomatyzowane podejmowanie decyzji w postaci profilowania opiera się o uzasadniony interes Administratora danycy osobowych w postaci zapewnienia możliwie najwyższej skuteczności podejmowanych przez Administratora działań marketingowych.</div>
                    <div>7. Decyzje wobec użytkowników są podejmowane z wykorzystaniem plików Cookies – zasady ich stosowania opisano w pkt. XI niniejszej polityki prywatności. Decyzje podejmowane w sposób automatyczny nie wpływają na sytuację użytkowników.</div>
                    <div>8. Osoba, która nie zgadza się z decyzją podjętą w sposób zautomatyzowany, jest uprawniona do zgłoszenia sprzeciwu, w tym poprzez złożenie stownej reklamacji. W takim wypadku należy skontaktować się z administratorem danych osobowych mailowo na adres: trelatattoo.zapisy@gmail.com i wskazać powody, dla których uważa, że podjęta decyzja jest niesłuszna lub nieprawidłowa. Administrator danych osobowych rozpatrzy indywidualnie każde zgłoszenie. Zgłoszenia nie są rozpatrywane za pomocą systemu informatycznego.</div>
                </div>
            
            <div className="h7">XIV. Postanowienia końcowe</div>
                <div className='ml-5'>
                    <div>1. W zakresie nieuregulowanym niniejszą Polityką prywatności obowiązują przepisy powszechnie obowiązującego prawa dotyczące ochrony danych osobowych.</div>
                    <div>2. O wszelkich zmianach wprowadzonych do niniejszej Polityki prywatności Administrator danych osobowych powiadomi Użytkowników poprzez informację wyświetlaną na jego stronie internetowej. Zmiana jest skuteczna od dnia umieszczenia stosownego komunikatu na stronie internetowej Administratora danych osobowych.</div>
                    <div>3. Niniejsza Polityka prywatności obowiązuje od dnia 10.08.2023 r</div>
                </div>

        </div>

      </section>
 
      {/* stopka - najważniejsze info */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
