import React from 'react';
import { Link } from "react-router-dom"

import{heroData} from '../data';
import {motion} from 'framer-motion';
import {fadeIn} from '../variants';

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  }
}

const Hero = () => {
  const {title, subtitle, btnText, btnIcon }= heroData
  return (
  <section id='home'
  className='bg-hero bg-cover bg-center min-h-[40vh] 
  lg:h-[948px] bg-no-repeat relative mt-[100px] lg:mt-[130px]'>{/* tło - link w tailwind.config.js*/}
    
    <motion.div variants={container} initial='hidden' whileInView={'show'} 
    className='container mx-auto 
    bg-green-100/10 min-h-[90vh] lg:h-full mh-full
    flex items-center justify-center xl:justify-end'>
      
      {/* text & btn */}
      <div className='text-white text-center lg:text-left
      lg:max-w-[640px] '> 
        {/* text - pobierany z data.js*/}
        {/*nagłówek */}
        <motion.h1 variants={fadeIn('down')} className='text-[60px] lg:text-[100px] tracking-[-2px] leading-none font-tertiary mb-4'>
          {title}
        </motion.h1>

        {/*treść */}
        <motion.p variants={fadeIn('down')} className='text-[60px] lg:text-[70px] tracking-[-2px] leading-none font-tertiary mb-4'>
          {subtitle}
        </motion.p>
        
        {/* btn 
        <motion.div variants={fadeIn('down')}>
          <button className='btn btn-sm lg:btn-lg 
          btn-outline mx-auto lg:mx-0'>
            {btnText}
            
            <div className='text-xl'>
              {btnIcon}
            </div>
            
            <Link to="/contact">contact</Link>

          </button>
        </motion.div>
        */}
      </div>

      {/* outline text (ten taki lekko widoczny na dole sekcji) 
      <div className='hidden xl:flex absolute -bottom-2 right-0 left-0 
        before: content-outlineText'></div>*/}
    </motion.div>
  </section>
  );
};

export default Hero;




