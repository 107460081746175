import React, {useState, useEffect} from 'react';

//import data
import {headerData} from '../data'

//komponenty
import Nav from './Nav'
import Socials from './Socials'

//ikony
import {TiThMenuOutline} from 'react-icons/ti'
import NavMobile from './NavMobile';

const Header = () => {
  //logo
  const {logo} = headerData
  //header state
  const[isActive, setIsActive] = useState(false);
  //navMobile state
  const[navMobile, setNavMobile] = useState(false);
  

  //scroll
  useEffect(() => {
    window.addEventListener('scroll', ()=>{
      window.scrollY>50 ? setIsActive(true) : setIsActive(false);
    });
  });

  
  return <header className={`
  ${isActive 
    ? 'lg:h-[100px] shadow-lg' 
    : ' lg:h-[150px] shadow-lg'} 
  fixed left-0 right-0 z-10 max-w-[1920px]
  w-full mx-auto transition-all duration-300 bg-white`}>

    {/* ustwienie zawartości nagłówka */}
    <div className='flex justify-between items-center 
    h-full pl-[50px] pr-[50px] h-[90px]'>
      {/*logo*/}
      <a href='/' >
        <img className='w-[100px] h-[100px]' src={logo} alt=''/>
      </a>

      {/* nav - początkowo ukryta*/}
      <div className='hidden xl:flex'>
        <Nav />
      </div>

      {/*przycisk menu */}
      <div 
        onClick={()=>setNavMobile(!navMobile)} 
        className='xl:hidden absolute right-[5%] p-2 rounded-md 
        cursor-pointer'
      >
        <TiThMenuOutline className='text-3xl'/>
      </div>

 
      {/* navMobile - dla dużych ekranów zakryta, pasek menu navigacji*/}
      <div 
        className={`
        ${navMobile ? 'max-h-full' : ' max-h-0'} 
        ${isActive ? 'top-[100px] lg:top-[100px]' : 'top-[100px] lg:top-[130px]'}
          fixed bg-white 
          w-full h-full left-0 -z-10 transition-all duration-300`}
      >  
        <NavMobile />
      </div>

      {/* social */}
      <div className='hidden xl:flex'>
        <Socials />
      </div>

    </div>
    
  </header>;
};

export default Header;
