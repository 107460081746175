import React, { useState, useEffect, useMemo } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom"
import { IoMdArrowForward } from 'react-icons/io';
import {tattosData} from '../galeryFiles/tattosData'
const GallerySkills = () => {
  
  const {status,data} = tattosData

  //funkcja klikania na obraz
  const [isActive, setIsActive] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  
  const openModal = (index) => {
    setActiveImageIndex(index);
    setIsActive(true);
  }

  const closeModal = () => {
    setActiveImageIndex(null);
    setIsActive(false);
  }

  //w zależności od statusu albo galeria albo komunikat o błędzie
  if(status === "ok") {
    return (
      <section 
        section id='gallery' 
        className='bg-[#808a6c] lg:py-16 xl:pb-[100px] py-[60px]'
      >
        <div className='container flex justify-center text-black mx-auto h2'>
            Tatuaże
          </div>
        {/* box w którym jest galeria */}
        <div className='container mx-auto pb-10'>
          
          {/**galeria ogólne style */}
          <div 
            className='text-black flex flex-col lg:flex-row lg:justify-between gap-x-6 gap-y-14'
            style={{ display: "flex", flexWrap: "wrap"}}
          >
            {/* listowanie itemów z bazy */}
            {data.filter(item=>item.show).map((item, index) => (
              <div 
                key={index} 
                onClick={() => openModal(index)}
                className='cursor-pointer'
              >
                <img
                  src={item.url}
                  alt={item.url}
                  width={item.width}
                  height={item.hight}
                />
              </div>
            ))}
          </div>

          
        </div>
        <div className='container flex justify-center  mx-auto'>
          <button className='btn btn-sm lg:btn-lg
            btn-outline mx-auto lg:mx-0'>
           
            <Link to="/tattos">Zobacz więcej</Link>
            <IoMdArrowForward />
          </button>
        </div>
        {/* Modal 
        {isActive && (
          <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-75'>
            <div className='flex justify-center items-center  '>
              <div className='flex flex flex-col lg:flex-row lg:justify-between 
                gap-x-6 gap-y-14 relative fixed top-[130px] left-0 gap-x-6'>
                
                *obraz  *
                <img className='h-[600px] lg:h-[750px] '
                  src={data[activeImageIndex].url} 
                  alt={data[activeImageIndex].url}
                  
                />

                
                  *przycisk *
                <button 
                  className='absolute top-[-20px] right-[-50px] p-10 text-white'
                  onClick={closeModal}
                >
                  <IoIosCloseCircleOutline fontSize="50px"/>
                </button>


                {
                <div className='pt-[10px] lg:pt-[100px] p-2 w-[400px]  bg-black bg-opacity-10
                flex-coll gap-y-1.5 inset-y-0 right-0'>
                  *tekst 
                  <div  className='text-white '><h4 className='h4'> {data[activeImageIndex].title}</h4></div>
                  <div  className='text-white py-5'><h5 className='h5'>{data[activeImageIndex].comment}</h5></div>
                </div>
                
              </div>
            </div>
          </div>
        )}*/}
      </section>
    );
    
  } else {
    return (
      <div>
        Wystąpił błąd, spróbój ponownie później.
      </div>
    )
  }

  
};

export default GallerySkills;
