import Contact from '../components/Contact';
import Footer from '../components/Footer';
import {Link } from "react-router-dom";

const ContactPage = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>
      
      {/* Dane kontaktowe */}
      <Contact />

      {/* stopka - najważniejsze info */}
      <Footer />
      <div className='bg-[#808a6c] flex justify-center'>
        <div className='text-white '>
          © WIKTORIA TRELA 2023. | WEB BY PIOTR WIEKIERA
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
