import React, { useState, useEffect, useMemo } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom"
import { IoMdArrowForward } from 'react-icons/io';
import {paintingsData} from '../galeryFiles/dataPaintings'

const GalleryPaint = () => {
 
  const {status,data} = paintingsData
  if(status=="ok"){
    console.log(paintingsData);
  }
  

  //funkcja klikania na obraz
  const [isActive, setIsActive] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  
  const openModal = (index) => {
    setActiveImageIndex(index);
    setIsActive(true);
  }

  const closeModal = () => {
    setActiveImageIndex(null);
    setIsActive(false);
  }

  //w zależności od statusu albo galeria albo komunikat o błędzie
  if(status === "ok") {
    return (


      <section 
        section id='obrazy' 
        className='bg-white lg:py-16 xl:pb-[100px] py-[60px]'
      >
        <div className='container flex justify-center  mx-auto h2'>
             Obrazy
          </div>
        {/* box w którym jest galeria */}
        <div className='container mx-auto pb-10'>
          
          {/**galeria ogólne style */}
          <div 
            className='text-black flex flex-col lg:flex-row lg:justify-between gap-x-6 gap-y-14'
            style={{ display: "flex", flexWrap: "wrap"}}
          >
            {/* listowanie itemów*/}
            {data.filter(item=>item.show).map((item, index) => (
              <div 
              key={index} 
              onClick={() => openModal(index)}
              className='cursor-pointer'
              >
                <img
                  src={item.url}
                  alt="Tu Powinien Być Obraz :("
                  width={item.width}
                  height={item.hight}
                />
              
              </div>
            ))}
          </div>

          
        </div>
        <div className='container flex justify-center  mx-auto'>
        <button className='btn btn-sm lg:btn-lg
          btn-outline mx-auto lg:mx-0'>
           
            <Link to="/paintings">Zobacz więcej</Link>
            <IoMdArrowForward />
          </button>
        </div>
    
      </section>
    );
    
  } else {
    return (
      <div>
        Wystąpił błąd, spróbój ponownie później.
      </div>
    )
  }

  
};


export default GalleryPaint;
