import React from 'react';
import {Link} from 'react-scroll'
//import data
import {navData} from '../data'

const Nav = () => {
  const {items} = navData

  return <nav className='w-full h-full flex
  justify-evenly overflow-hidden'>
    <ul className='flex justify-center items-center gap-x-[70px]'>
      
      {items.map((item, index) => {
        return<li key={index} 
        className='link hover:border-b-2 hover:border-dark 
        transition duration-300 pl-[20px] pr-[20px]'> 
         
          <Link 
            to={item.href}
            activeClass='active'
            spy={true}
            smooth={true}
            duration={500}
            offset={-70}
            className='text-xl font-primary uppercase'
            > 
            {item.name}
          </Link>
          
        
          {/*<a className='link hover:border-b-2 hover:border-dark 
          transition duration-300 ' 
          href={item.href}>
            {item.name}
      </a>*/}
        
        
        </li>
      })}

    </ul> 

  </nav>;
};

export default Nav;
