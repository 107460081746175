import React, { useEffect } from 'react';
import {Link } from "react-router-dom";

import Footer from '../components/Footer';
import {obrazy, tattoFAQ} from '../galeryFiles/faq';

const QAPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Ustawia widok strony na górze po załadowaniu lub nawigacji
  }, []);

  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>

      <section className='bg-white lg:py-16 xl:pb-[100px]'>
        {/* Header */}
        <div className="container mx-auto pb-[60px]">        
          <div className='flex flex-col lg:flex-col gap-x-[30px] items-center'>
              <div className='h6'>
              FAQ
              </div>
          </div>
        </div>

        <div className="container mx-auto pb-[60px]">        
          <div className='flex flex-col items-left py-5 h7'>
            OBRAZY
          </div>

          <div className='flex flex-col gap-y-5 items-left pl-10'>
            {obrazy.data.map((item, index) => (
              <div>
                <div className='font-bold'>{item.header}</div>
                <div>{item.text}</div>
              </div>
            ))}
          </div>

        </div>

        <div className="container mx-auto p-5">        
          <div className='flex flex-col items-left py-5 h7'>
            TATUAŻE
          </div>

          <div className='flex flex-col gap-y-5 items-left pl-10'>
            {tattoFAQ.data.map((item, index) => (
              <div>
                <div className='font-bold'>{item.header}</div>
                
                <div>
                  {item.text.map((item, index) => (
                    <div>
                      <div>
                        {item.line}
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        </div>

      </section>


      {/* stopka - najważniejsze info */}
      <Footer />
    </div>
  );
};

export default QAPage;
