import React, { useEffect } from 'react';
import {Link } from "react-router-dom";

import Footer from '../components/Footer';
import GallerySubSkills from '../components/GallerySubSkills';

const TermsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Ustawia widok strony na górze po załadowaniu lub nawigacji
  }, []);

  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      <div className='bg-[#808a6c]'>
        <button className='btn btn-sm lg:btn-lg 
            btn-outline mx-auto lg:mx-0'>
          
          <Link to="/"> home</Link>
        </button>
      </div>
 
      <section className='bg-white lg:py-16 xl:pb-[100px]'>
        {/* Header */}
        
        <div className="container mx-auto pb-[60px]">        
          <div className='flex flex-col lg:flex-col gap-x-[30px] items-center'>
              <div className='h6'>
              REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ
              </div>
              <div className='h6'>
              https://wiktoriatrela.pl/
              </div>
          </div>
        </div>

        <div className="container mx-auto flex flex-col gap-y-4">
          <div className="h7">§1 POSTANOWIENIA OGÓLNE</div>
            <div className='ml-5'>
              <div>1. Na podstawie art. 8 ust. 1 Ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną, Operator tj. Wiktoria Trela, publikuje niniejszym Regulamin świadczenia usług drogą elektroniczną.</div>
              <div>2. W Regulaminie, o którym mowa w ust. 1 powyżej, określono:</div>
                <div className='ml-5'>
                  <div>a) Zakres i rodzaj usługi świadczonej drogą elektroniczną na Stronie</div>
                  <div>b) Zasady funkcjonowania Strony oraz korzystania z usługi świadczonej drogą elektroniczną, udostępnionej w Serwisie, w tym w szczególności prawa i obowiązki Operatora i Użytkowników.</div>
                  <div>c) Zasady ochrony danych osobowych Użytkowników.</div>
                </div>

              <div>3. Regulamin jest udostępniany nieodpłatne Użytkownikom przez Operatora przez zawarcie umowy o świadczenie Usługo drogą elektroniczną.</div>
              <div>4. Operator jest zobowiązany do świadczenia Usługi zgodnie z Regulaminem i Ustawą oraz zapewnienia pełnej dostępności Regulaminu dla każdego Użytkownika w sposób zapewniający jego pobranie, odtworzenie i utrwalenie za pomocą systemu informatycznego, z którego korzysta Użytkownik.</div>
              <div>5. Każdy Użytkownik jest zobowiązany zapoznać się z Regulaminem przed skorzystaniem z Usługi i przestrzegać postanowień Regulaminu od momentu dokonania akceptacji.</div>
              <div>6. Postanowienia Regulaminu, które nie zostały udostępnione Użytkownikom w sposób wskazany w ust. 3 powyżej, nie są wiążące dla Użytkowników.</div>
              <div>7. Brak zapoznania się z Regulaminem i akceptacji jego postanowień powoduje niemożność skorzystania z Usługi.</div>
              <div>8. Użytkownik, przed rozpoczęciem korzystania z Usługi, jest zobowiązany do wyrażenia zgody na jej świadczenie na zasadach wskazanych w Regulaminie. Udzielenie zgody polega na zaznaczeniu odpowiednio widocznego elementu formularza znajdującego się na Stron o treści: „Akceptuję Regulamin świadczenia usług drogą elektroniczną”</div>
              
            </div>

          <div className="h7">§2 DEFINICJE</div>
            <div className='ml-5'>
              <div>Użyte w niniejszym Regulaminie określenia mają następujące znaczenie:</div>
              <div>a) Adres elektroniczny/adres e-mail - oznaczenie systemu teleinformatycznego umożliwiające porozumiewanie się za pomocą środków komunikacji elektronicznej, w szczególności poczty elektronicznej (adres e-mail);</div>
              <div>b) Operator – podmiot, który jest właścicielem Strony oraz nim zarządza, tj. Wiktoria Trela email: trelatattoo.zapisy@gmail.com</div>
              <div>c) Regulamin – niniejszy regulamin świadczenia usług drogą elektroniczną, który określa zasady korzystania i funkcjonowania Serwisu oraz prawa i obowiązki Operatora i Użytkowników, obejmujące warunki świadczenia usług drogą elektroniczną za jego pośrednictwem;</div>
              <div>d) RODO – Rozporządzenie Parlamentu Europejskiej i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych);</div>
              <div>e) Strona – serwis internetowy dostępny pod adresem samsaratattoo.pl, zarządzany i prowadzony przez Operatora.</div>
              <div>f) System teleinformatyczny – zespół urządzeń informatycznych oraz oprogramowania, współpracujących ze sobą, zapewniający przetwarzanie, przechowywanie, wysyłanie oraz odbieranie danych poprzez sieci telekomunikacyjne za pomocą właściwego dla danego rodzaju sieci telekomunikacyjnego urządzenia końcowego w rozumieniu ustawy Prawo Telekomunikacyjne.</div>
              <div>g) Świadczenie usług drogą elektroniczną – wykonanie Usług, polegające na wysyłaniu i odbieraniu danych z użyciem systemów informatycznych, na indywidualne żądanie Użytkownika, bez konieczności jednoczesnej obecności stron, przy czym dane są transmitowane z zastosowaniem sieci publicznych w rozumieniu ustawy Prawo Telekomunikacyjne.</div>
              <div>h) Usługa – usługa świadczona przez Operatora za pośrednictwem Strony przy wykorzystaniu Systemu Teleinformatycznego, polegająca na umożliwieniu Użytkownikom skorzystania z formularza kontaktowego zgodnie z niniejszym Regulaminem.</div>
              <div>i) Ustawa – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;</div>
              <div>j) Użytkownik – osoba fizyczna, prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, korzystająca z Usługi świadczonej przez Operatora przy pomocy Strony za pośrednictwem formularza udostępnionego na Stronie.</div>
              <div>k) Prawo Telekomunikacyjne – ustawa z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne;</div>
            </div>
          
          <div className="h7">§3 USŁUGA ŚWIADCZONA DROGĄ ELEKTRONICZNĄ - FORMULARZ KONTAKTOWY</div>
            <div className='ml-5'>
              <div>1. Za pośrednictwem Strony Operator świadczy na rzecz Użytkowników Usługę, która stanowi usługę świadczoną drogą elektroniczną, tj. Formularz Kontaktowy – polegającą na bezpłatnym udostępnieniu Użytkownikom modułu służącego do kontaktu z Operatorem, umożliwiającym uzyskanie informacji o działalności Operatora, jego ofercie, dostępności świadczonych w ramach prowadzonej działalności gospodarczej usług.</div>
              <div>2. Treści udostępnione na Stronie stanowią wyłączną własność Operatora. Kopiowanie treści udostępnionych przez Operatora w celach komercyjnych bez uzyskania uprzedniej pisemnej zgody Operatora jest zabronione.</div>
              <div>3. Umowa o świadczenie usługi Formularza Kontaktowego jest zawierana na czas określony i ulega rozwiązaniu z chwilą wysłania odpowiedzi na zapytanie Użytkownika, które zostało złożone za pośrednictwem formularza kontaktowego. Usługa ma charakter nieodpłatny.</div>
              <div>4. Formularz Kontaktowy został udostępniony Użytkownikom w zakładce „Kontakt” udostępnionej na głównej odsłonie Strony w prawym górnym rogu.</div>
              <div>5. Zawarcie umowy o świadczenie Usługi wymaga podjęcia przez Użytkownika działań polegających na wypełnieniu pól formularza, dostępnego w zakładce „Kontakt” i podania imienia i nazwiska, adresu email oraz treści wiadomości</div>
              <div>6. Ponadto Użytkownik, aby móc skorzystać, z Usługi, jest zobowiązany do:</div>
                <div className='ml-5'>
                  <div>a) Zaakceptowania Regulaminu poprzez zaznaczenie przycisku wyboru o treści: „Akceptuję regulamin świadczenia usług drogą elektroniczną”</div>
                  <div>b) Zaznaczenie przycisku wyboru dotyczącego zapoznania się z polityką prywatności Strony, obejmującą informację o przetwarzaniu danych osobowych.</div>
              </div>

              <div>7. Do zawarcia umowy o świadczenie usługi Formularza Kontaktowe dochodzi z chwilą kliknięcia przez Użytkownika przycisku „Wyślij”.</div>
              <div>8. Wykonanie czynności wskazanych w ust. 6 i 7 powyżej przez Użytkownika jest równoznaczne z:</div>
                <div className='ml-5'>
                  <div>a) wyrażeniem przez Użytkownika zgody na świadczenie przez Operatora drogą elektroniczną usługi Formularz Kontaktowy,</div>
                  <div>b) wyrażeniem przez Użytkownika zgody na przetwarzanie jego danych osobowych przez Operatora w celu świadczenia usługi Formularz Kontaktowy zgodnie z zasadami przewidzianymi w RODO, w szczególności w art. 7 RODO.</div>
                </div>

              <div>9. Użytkownik jest uprawniony do rezygnacji z Usługi do momentu otrzymania odpowiedzi na zapytanie złożone za pośrednictwem Formularza Kontaktowego. Rezygnacja z Usługi, o której mowa w zdaniu poprzednim, polega na wysłaniu z adresu email Użytkownika, wskazanego w Formularzu Kontaktowym, oświadczenia o rezygnacji z Usługi. Oświadczenie należy skierować na adres: trelatattoo.zapisy@gmail.com</div>
              <div>10. Operator zastrzega sobie prawo do zaprzestania świadczenia usługi Formularz Kontaktowy w każdym momencie.</div>
              <div>11. W wypadku, o którym mowa w ust. 10 powyżej, Operator jest bezwzględnie zobowiązany do usunięcia wszelkich danych osobowych Użytkownika zebranych w celu świadczenia usługi Formularz Kontaktowy.</div>             
            </div>
          
          <div className="h7">§4 WARUNKI ŚWIADCZENIA USŁUGI DROGĄ ELEKTRONICZNĄ</div>
            <div className='ml-5'>
              <div>1. Korzystanie z Usługi, udostępnionej na Stronie, na podstawie niniejszego Regulaminu, wymaga dostępu do urządzenia pozwalającego na korzystanie z zasobów sieci Internet, posiadania adresu email oraz skorzystania z przeglądarki internetowej, umożliwiającej wyświetlanie stron internetowych z włączoną obsługą plików „cookies” oraz innych podobnych technologii.</div>
              <div>2. Użytkownik, korzystając z usługi, ma obowiązek:</div>
                <div className='ml-5'>
                  <div>a) Powstrzymać się od publikacji i przesyłania treści mających charakter obraźliwy, sprzeczny z prawem, naruszający dobra osobiste osób trzecich, podlegające ochronie prawnej, jak również prawa autorskie i wizerunek osób trzecich.</div>
                  <div>b) Korzystania z Usługi w sposób odpowiadający wymogom wynikającym z przepisów prawa, dobrych obyczajów oraz nienaruszający dóbr osobistych Operatora.</div>
                  <div>c) Powstrzymać się od wykorzystania Usługi do publikacji reklam i wszelkich informacji o charakterze komercyjnym, w tym w szczególności przez rozpowszechnianie niezamówionej informacji handlowej, podejmowania jakichkolwiek działań utrudniających lub zakłócających funkcjonowanie Strony.</div>
              </div>

              <div>3. Operator jest uprawniony do odmowy świadczenia Usługi w przypadku naruszenia przez Użytkownika niniejszego Regulaminu lub przepisów powszechnie obowiązującego prawa, podjęcia przez Użytkownika działań, które wpływają w niezakłócone korzystanie ze Strony lub Usługi przez innych użytkowników, podania błędnych lub nieprawdziwych danych, które są wymagane do prawidłowej subskrypcji Usługi, jak również z uwagi na czynności konserwacyjne lub związane z modyfikacją Stron.</div>
              <div>4. W przypadku otrzymania przez Operatora rezygnacji ze świadczonej Usługi, dane osobowe Użytkownika są usuwane z bazy adresowej niezwłocznie, nie później jednak niż w terminie 48 godzin od chwili otrzymania rezygnacji, złożonej zgodnie z postanowieniami niniejszego Regulaminu. Operator każdorazowo powiadamia Użytkownika o usunięciu jego danych poprzez wysłanie wiadomości email na adres email Użytkownika.</div>
              
            </div>
          
          <div className="h7">§5 OSTRZEŻENIE CO DO KORZYSTANIA Z USŁUGI</div>
            <div className='ml-5'>
              <div>1. Operator informuje i zastrzega, że korzystanie z Usługi może wiązać się ze standardowym ryzykiem wynikającym z wykorzystania Internetu i wskazuje Użytkownikom, aby przedsięwzięli odpowiednie kroki w celu zminimalizowania ryzyka.</div>
              <div>2. W związku z ryzykiem wskazanym w ust. 1 powyżej, Operator rekomenduje podjęcie przez Użytkownika odpowiednich kroków w celu minimalizacji wskazanego ryzyka.</div>
              <div>3. Operator zapewnia i zobowiązuje się, że działanie systemu informatycznego, którym się posługuje, daje możliwość korzystania z Usługi w sposób uniemożliwiający dostęp osób nieuprawnionych do treści informacji składających się na Usługi.</div>
            </div>
          
          <div className="h7">§6 OCHRONA DANYCH OSOBOWYCH</div>
            <div className='ml-5'>
              <div>1. Zasady i informacje dotyczące ochrony danych osobowych uregulowano w Polityce prywatności – wiktoriatrela.pl</div>
            </div>
          
          <div className="h7">§7 ODPOWIEDZIALNOŚĆ</div>
            <div className='ml-5'>
              <div>1. Operator nie ponosi odpowiedzialności za przerwę w dostępie do Usługi, powstałą w wyniku niezbędnych działań technicznych i administracyjnych, za zakłócenia w działaniu sieci Internet i jej dostaw jak również w przypadku wystąpienia przerwy w dostępie do Usługi w przypadkach wywołanych siłą wyższą, zaprzestanie świadczenia Usługi wobec Użytkowników naruszających postanowienia niniejszego Regulaminu, lub którzy dopuścili się szkody na rzecz osób trzecich na skutek korzystania z Usługi w sposób sprzeczny z Regulaminem lub przepisami powszechnie obowiązującego prawa.</div>
              <div>2. W przypadku otrzymania przez Operatora urzędowego powiadomienia o bezprawnym charakterze przechowywanych danych, które Użytkownik dostarczył Operatorowi i uniemożliwienia dostępu do tych danych, Operator nie ponosi odpowiedzialności wobec Użytkownika za szkodę spowodowaną brakiem dostępu do tych danych.</div>
              <div>3. Każda ze stron stosunku prawnego, który został nawiązany na podstawie niniejszego Regulaminu, jest zobowiązana do naprawienia szkody, którą druga strona poniosła w związku z niewykonaniem lub nienależytym wykonaniem obowiązków wynikających z Regulaminu, za wyjątkiem sytuacji, w których niewykonanie lub nienależyte wykonanie zobowiązań wynikających z Regulaminu jest skutkiem okoliczność, za które żadna ze stron nie ponosi odpowiedzialności.</div>
            </div>
          
          <div className="h7">§8 REKLAMACJE</div>
            <div className='ml-5'>
              <div>1. W kwestiach związanych z realizacją Usługi, wskazanej w niniejszym Regulaminie, Użytkownicy są uprawnieni do złożenia reklamacji.</div>
              <div>2. Reklamacja, o której mowa w ust. 1 powyżej, powinna zawierać co najmniej wskazanie imienia i nazwiska Użytkownika, jego adresu email oraz opisu okoliczność dotyczących reklamacji.</div>
              <div>3. Reklamacje, które nie zawierają danych wskazanych w ust. 2 powyżej, nie będą rozpatrywane przez Operatora.</div>
              <div>4. Reklamacje mogą być kierowane w formie wiadomości email na adres elektroniczny: trelatattoo.zapisy@gmail.com</div>
              <div>5. Reklamacje są rozpatrywane w terminie 14 dni od dnia doręczenia reklamacji Operatorowi.</div>
              <div>6. Operator poinformuje Użytkownika w przedmiocie reklamacji w terminie wskazanym w ust. 5 powyżej. Informacja zostanie przesłana na adres elektroniczny (email) podany przez Użytkownika w treści reklamacji.</div>
            </div>
          
          <div className="h7">§9 POSTANOWIENIA KOŃCOWE</div>
            <div className='ml-5'>
              <div>1. Regulamin jest dostępny na Stronie pod adresem https://wiktoriatrela.pl/ zakładce „Regulamin”.</div>
              <div>2. W sprawach nieuregulowanych w Regulaminie, zastosowanie mają przepisy powszechnie obowiązującego prawa, w szczególności RODO.</div>
              <div>3. Wszelkie spory dotyczące Regulaminu będą rozstrzygane przez Sąd powszechny właściwy ze  względu na siedzibę Operatora.</div>
              <div>4. Operator jest uprawniony do jednostronnej zmiany Regulaminu z ważnych powodów, w szczególności w przypadku zmiany przepisów prawa w zakresie mającym wpływ na realizację postanowień Regulaminu.</div>
              <div>5. Operator powiadamia Użytkowników o każdej zmianie Regulaminu poprzez publikację informacji na Stronie oraz w drodze powiadomienia wysyłanego na adres elektroniczny (email) Użytkownika.</div>
              <div>6. Regulamin wchodzi w życie z dniem 10.08.2023.</div>
              
            </div>
        
        </div>



      </section>

      {/* stopka - najważniejsze info */}
      <Footer />
    </div>
  );
};

export default TermsPage;
