import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import GallerySection from '../components/GallerySection';
import GallerySkills from '../components/GallerySkills';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import GalleryPaint from '../components/GalleryPaint'

const MainPage = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
      {/* nagłówek */}
      <Header /> 

      {/* sekcja pierwsza */}
      <Hero />

      {/* sekcja 2 - o tatuażach */}
      <About />

      {/* galerja obrazów */}    
      <GalleryPaint />

      {/* Zdjęcia tatuaży*/}
      <GallerySkills />
      {/* galerja wzorów */}
      <GallerySection />
      
      {/* Dane kontaktowe */}
      <Contact />

      {/* stopka - najważniejsze info */}
      <Footer />
    </div>
  );
};

export default MainPage;
