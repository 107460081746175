import React from 'react';
//import form
import ContactForm from './ContactForm.js';
//import data
import {contactData} from '../data'
//animacje
import {motion} from 'framer-motion';
import {fadeIn} from '../variants';
import {FaMapMarkerAlt} from 'react-icons/fa';


const Contact = () => {
  const {title, info,form} = contactData
  return <section id='contact'>
    <div className='container mx-auto pt-[90px] pb-[90px]'>
      <div className='flex flex-col xl:flex-row gap-y-16 '>
        {/*text */}
        
        <div className='flex-1'>

          <h2 className='text-[40px] xl:text-[40px] text-black uppercase font-primary leading-none mb-[20px] '> 
            BĄDŹMY W KONTAKCIE 
          </h2>

          <div className='flex flex-col justify-between xl:flex-col gap-x-16 gap-y-16 xl:gap-y-0 border-b border-dark pb-[5px]'>
            
            <div className='border-b border-dark pb-[5px] mb-8'>
              <div className='flex flex-col gap-y-3 '>
                <div className='font-primary uppercase font-medium text-xl mb-3'>
                  OBRAZY
                </div>

                <div className='flex items-center gap-[10px]'>
                  <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em]'>
                    Spodobały Ci się moje obrazy, chcesz dowiedzieć się więcej o ich dostępności lub zadać pytanie - napisz do mnie, a ja z największą przyjemnością opowiem Ci o nich więcej!
                  </div>
                </div>
              </div>
            </div>
            
            <div className='border-b border-dark pb-[5px]'>
              <div className='font-primary uppercase font-medium text-xl mb-3'>
                PRESS & MEDIA
              </div>

              <div className='flex items-center gap-[10px]'>
                <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em]'>Jeśli podobają Ci się moje prace i chciałbyś zaprosić mnie do wywiadu lub wernisażu lub innej formy współpracy chętnie ją nawiążę.</div>
              </div>
            </div>
            
            <div className='font-primary uppercase font-medium text-xl mb-3 mt-8'>
              TATUAŻE
            </div>

            <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em]'>
            Jeśli podoba Ci się moja stylistka i chciałbyś posiadać tatuaż ode mnie lub chciałbyś umówić się na bezpłatne konsultacje zachęcam do skontaktowania się ze mną.
            </div>

            <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em]'>
              Przy zapisach zamieść informacje tj.: opis pomysłu, przybliżony rozmiar, miejsce na ciele, preferowany termin oraz inspiracje lub jeden z moich wolnych projektów trelatattoo.zapisy@gmail.com
            </div>

            
            <ul className='flex flex-col justify-center items-center gap-y-4 '>
              <li> 
                <a href='https://samsaratattoo.pl/'>
                  Pracownia Tatuażu Artystycznego SAMSARA
                </a>
              </li>
            </ul>

            <ul className='mt-2'>
              <li> 
                <a href='https://www.google.com/maps/place/SAMSARA/@50.0760877,19.9167754,17z/data=!4m15!1m8!3m7!1s0x47165baddaf0b6db:0xb9fd86d3ead34c66!2zUmFjxYJhd2lja2EgMjYvNCwgMzAtMDc1IEtyYWvDs3c!3b1!8m2!3d50.0760843!4d19.9193503!16s%2Fg%2F11k7lwl2_k!3m5!1s0x47165baddaf0b6db:0x65ed1dab5a19c10d!8m2!3d50.0760843!4d19.9193503!16s%2Fg%2F11c6cqvn9r?entry=ttu'>
                  
                  <div className='flex justify-center gap-x-4 '>
                    <div>
                      <FaMapMarkerAlt />
                    </div>
                    <div>
                      Racławicka 26/4, 30-075 Kraków
                    </div>
                  </div>
                    
                </a>
              </li>
            </ul>
           
          </div>
        </div>


        {/*form */}
        <motion.div
            variants={fadeIn('left')}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.4 }}
            className='flex-1 xl:pl-[40px] flex justify-center items-center '
          >
            <ContactForm />
        </motion.div>

      </div>
    </div>
  </section>;
};

export default Contact;
