import React from 'react';
import {useRef} from 'react';

import emailjs from 'emailjs-com'
//import data
import {formData} from '../data'

const ContactForm = () => {
  const {name, email, message, attachment, btnText} = formData
  const form = useRef(); //imputy do maila

  //z wykorzystanniem emailjs wysyłam maila
  //https://www.emailjs.com/docs/examples/reactjs/
  const  sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm(
      'service_1twf5xm', //zintegrowana poczta z której się wysyła
      'template_5ibhhem', //wzór wiadomości 
      form.current, //imputy
      'y6YD1NdVaVETmAcPq' //klucz publiczy z konta
    ).then(
      (result) => { 
        alert('Dzięki za kontakt, odezwę się ;-)')
        console.log(result.text);}, 
      (error) => { 
        alert('Coś poszło nie tak, spróbuj jeszcze raz później albo nie czekaj i skontaktuj się na podany ardes')
        console.log(error.text); 
      }
    );
  };

  return <form 
  className='flex flex-col gap-y-10 w-full' 
  ref={form}
  onSubmit={sendEmail}>

    <input
      className='border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4'
      type='text' //typ imputu
      name='name'   //jak się odnosić do tego
      placeholder={name}  //zawartość pola
      required
    />
    
    <input
      className='border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4'
      type='text'
      name='email'
      placeholder={email} 
      required
    />

    <textarea 
      className='border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-3 xl:mb-[10px]'
      type='text'
      name='message'
      placeholder={message}
      required
    />

    <button type='submit' className='self-start btn btn-sm btn-dark'>
      {btnText}
    </button>

  </form>
  
}

export default ContactForm;
